<div class="container-fluid p-0" style="width: 90vw; overflow: hidden">

  <div class="row">
    <div class="col-12">
      <div class="login-card login-dark">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage" style="max-width: 40%" />
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" />
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Sign in to account</h4>
              <p>Enter your email & password to login</p>
              <div class="form-group">
                <label for="userName" class="col-form-label">Email Address</label>
                <input class="form-control" id="userName" name="userName" autocomplete="off" type="text" required="" placeholder="your user name" formControlName="username" />
                <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.required" class="text text-danger mt-1">Email is required</div>
                <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.username" class="text text-danger mt-1">Invalid Email</div>
              </div>
              <div class="form-group">
                <label for="password" class="col-form-label">Password</label>
                <input class="form-control" autocomplete="off"  id="password" name="password" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********" />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">Password is required</div>
              </div>
              <div class="form-group mb-0">

                  <button class="btn btn-primary d-block w-100"  [disabled]="!loginForm.valid" (click)="login()" type="submit"><span>Sign in</span></button>
                </div>
              <app-alert></app-alert>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

