import {Component, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DataTableDirective, DataTablesModule} from "angular-datatables";
import {Observable} from "rxjs";
import {CompanyDB} from "../../shared/data/tables/company";
import {HttpClient} from "@angular/common/http";
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {NgbdSortableHeader, SortEvent} from "../../shared/directives/NgbdSortableHeader";
import {CONFIG} from "../../../../config";
import {SharedModule} from "../../shared/shared.module";
import {DatePipe, DecimalPipe, NgForOf, NgIf} from "@angular/common";

@Component({
    selector: 'app-pay-out-statement',
    standalone: true,
    imports: [
        SharedModule,
        DataTablesModule,
        DatePipe,
        NgForOf,
        NgIf,
        DecimalPipe
    ],
    templateUrl: './pay-out-statement.component.html',
    styleUrl: './pay-out-statement.component.scss'
})
export class PayOutStatementComponent {
    @ViewChild(DataTableDirective)
    datatableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, {static: false})
    dtElement!: DataTableDirective;
    public selected = [];
    dataTableParam: any;
    statement: any[] = [];
    displayMonths = 1;
    navigation = 'select';
    outsideDays = 'visible';
    showWeekNumbers = true; // Define the showWeekNumbers property here
    selectedStatus: any = "";

    public tableItem$: Observable<CompanyDB[]>;
    public searchText;
    total$: Observable<number>;
    persons: any[] = [

        {date: '2024-03-01', credit: 1000, debit: 0, closing: 1000, description: 'Salary', fromTo: 'Employer/asdf'},
        {date: '2024-03-05', credit: 0, debit: 500, closing: 500, description: 'Rent', fromTo: 'Landlord/gfgh'},
        {date: '2024-03-10', credit: 200, debit: 0, closing: 700, description: 'Bonus', fromTo: 'Company/wert'},
        // Add more dummy data as needed
    ];
    formatedStartDate: any;
    formatedEndDate: any;

    constructor(private http: HttpClient, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {

        // this.fromDate = calendar.getToday();
        // this.toDate = calendar.getNext(calendar.getToday(), "d", 10);

    }

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
    preVoidList: any[] = []


    onSort({column, direction}: SortEvent) {
        // console.log('sorting')
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

    }

    ngOnInit(): void {
        const currentDate = new Date();
        let previousDay = currentDate.getDate() - 1;
        if (previousDay === 0) {
            const lastDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
            previousDay = lastDayOfPreviousMonth;
            this.startDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth(), previousDay);
        } else {
            this.startDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, previousDay);
        }
        this.endDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
        this.formatedStartDate = this.formatDate(this.startDate);
        this.formatedEndDate = this.formatDate(this.endDate);
        const that = this;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            searching: true,
            autoWidth: false,
            processing: false,
            order: [0, 'desc'],
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.userPayoutStatement, Object.assign(dataTablesParameters, {
                            status: this.selectedStatus,
                            startDate: this.formatedStartDate,
                            endDate: this.formatedEndDate
                        })).subscribe(resp => {
                    let data = resp.data.original.data;
                    this.dataTableParam = dataTablesParameters;
                    if (resp.data) {
                        that.statement = data;
                    }

                    callback({
                        recordsTotal: resp.data.original.recordsTotal,
                        recordsFiltered: resp.data.original.recordsFiltered,
                        data: []
                    });
                });
            },
            columns: [
                {data: 'createdAt'},
                {data: 'withdraw'},
                {data: 'deposit'},
                {data: 'bankBalance'},
                {data: 'remark'},
                {data: 'userName'} // Include action column
            ]
        };


    }

    delete(person) {

    }


    startDate: any;
    endDate: any;

    formatDate(date: any) {
        if (date) {
            const year = date.year.toString().padStart(4, '0');
            const month = date.month.toString().padStart(2, '0');
            const day = date.day.toString().padStart(2, '0');
            return `${year}-${month}-${day}T00:00:00.000Z`;
        }
        return null;
    }

    onDateChange() {
        // Handle date change logic here
        this.formatedStartDate = this.formatDate(this.startDate);
        this.formatedEndDate = this.formatDate(this.endDate);
        // console.log('Start Date:', this.formatedStartDate);
        // console.log('End Date:', this.formatedEndDate);
    }

    rerender(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    onSelectionChange() {
        // console.log(this.selectedStatus)
        this.rerender()
    }
}
