import {Component, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {SharedModule} from "../../shared/shared.module";
import {DataTableDirective, DataTablesModule} from "angular-datatables";
import {Observable} from "rxjs";
import {COMPANYDB, CompanyDB} from "../../shared/data/tables/company";
import {TableService} from "../../shared/services/table.service";
import {HttpClient} from "@angular/common/http";
import {NgbdSortableHeader, SortEvent} from "../../shared/directives/NgbdSortableHeader";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-pay-in-statement',
  standalone: true,
  imports: [
    SharedModule,
    DataTablesModule
  ],
  templateUrl: './pay-in-statement.component.html',
  styleUrl: './pay-in-statement.component.scss'
})
export class PayInStatementComponent {
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, {static: false})
  dtElement!: DataTableDirective;
  public selected = [];
  displayMonths = 1;
  navigation = 'select';
  outsideDays = 'visible';
  showWeekNumbers = true; // Define the showWeekNumbers property here

  public tableItem$: Observable<CompanyDB[]>;
  public searchText;
  total$: Observable<number>;
  persons: any[] = [
    { id: 1, firstName: 'John', lastName: 'Doe' },
    { id: 2, firstName: 'Jane', lastName: 'Smith' },
    { id: 3, firstName: 'Alice', lastName: 'Johnson' },
    { id: 4, firstName: 'Bob', lastName: 'Brown' },
    // Add more dummy data as needed
  ];

  constructor(public service: TableService,private http: HttpClient) {

    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(COMPANYDB)

  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  preVoidList: any[] = []


  onSort({ column, direction }: SortEvent) {
    console.log('sorting')
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  ngOnInit(): void {
    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',

      pageLength: 2,
      serverSide: false,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        that.http
            .post<any>(
                '',
                dataTablesParameters, {}
            ).subscribe(resp => {

          that.persons =  [
            { id: 1, firstName: 'John', lastName: 'Doe' },
            { id: 2, firstName: 'Jane', lastName: 'Smith' },
            { id: 3, firstName: 'Alice', lastName: 'Johnson' },
            { id: 4, firstName: 'Bob', lastName: 'Brown' },
            // Add more dummy data as needed
          ];

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [{ data: 'id' }, { data: 'firstName' }, { data: 'lastName' }]
    };
  }


  startDate: NgbDateStruct;
  endDate: NgbDateStruct;

  // Other properties and methods

  onDateChange() {
    // Handle date change logic here
    // console.log('Start Date:', this.startDate);
    // console.log('End Date:', this.endDate);
  }
}
