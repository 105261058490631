import {Component, OnDestroy} from '@angular/core';
import {Subscription} from "rxjs";
import {NotificationService} from "../../services/notification.service";
import {NgForOf} from "@angular/common";
import {NgbAlert} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-alert',
    standalone: true,
    imports: [
        NgForOf,
        NgbAlert
    ],
    templateUrl: './alert.component.html',
    styleUrl: './alert.component.scss'
})
export class AlertComponent implements OnDestroy {
    private subscription: Subscription;
    alerts: any[] = [];

    constructor(private alertService: NotificationService) {
        this.subscription = alertService.getAlerts().subscribe(alert => {
            if (alert) {
                this.alerts.push(alert);
                // Automatically remove alert after 5 seconds
                setTimeout(() => {
                    this.removeAlert(alert);
                }, 3000);
            } else {
                // Clear alerts when empty message is received
                this.alerts = [];
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    removeAlert(alert: any) {
        const index = this.alerts.indexOf(alert);
        if (index !== -1) {
            this.alerts.splice(index, 1);
        }
    }

    close(alert: any) {

    }
}
