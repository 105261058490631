import {Component, Inject, OnInit} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {NavService} from "../../services/nav.service";
import {LayoutService} from "../../services/layout.service";
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import {BackendService} from "../../services/backend.service";
import {interval} from "rxjs";
import {DataShareService} from "../../services/data-share.service";

SwiperCore.use([Navigation, Pagination, Autoplay]);

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    public elem: any;
    totalBalance: any;

    constructor(public layout: LayoutService,
                private backend: BackendService,
                private dataShareService: DataShareService,
                public navServices: NavService, @Inject(DOCUMENT) private document: any) {
    }

    ngOnInit() {
        this.elem = document.documentElement;
            this.getBalance();
    }

    getBalance() {
        this.backend.userBalance().subscribe(data => {
            this.totalBalance = data.data.bankBalance
            this.dataShareService.setSharedBalance(this.totalBalance)
        })
    }

    sidebarToggle() {
        this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
        this.navServices.megaMenu = false;
        this.navServices.levelMenu = false;
    }

    layoutToggle() {
        if ((this.layout.config.settings.layout_version = "dark-only")) {
            document.body.classList.toggle("dark-only");
        }
        document.body.remove;
    }

    searchToggle() {
        this.navServices.search = true;
    }

    languageToggle() {
        this.navServices.language = !this.navServices.language;
    }

    toggleFullScreen() {
        this.navServices.fullScreen = !this.navServices.fullScreen;
        if (this.navServices.fullScreen) {
            if (this.elem.requestFullscreen) {
                this.elem.requestFullscreen();
            } else if (this.elem.mozRequestFullScreen) {
                /* Firefox */
                this.elem.mozRequestFullScreen();
            } else if (this.elem.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                this.elem.webkitRequestFullscreen();
            } else if (this.elem.msRequestFullscreen) {
                /* IE/Edge */
                this.elem.msRequestFullscreen();
            }
        } else {
            if (!this.document.exitFullscreen) {
                this.document.exitFullscreen();
            } else if (this.document.mozCancelFullScreen) {
                /* Firefox */
                this.document.mozCancelFullScreen();
            } else if (this.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.document.webkitExitFullscreen();
            } else if (this.document.msExitFullscreen) {
                /* IE/Edge */
                this.document.msExitFullscreen();
            }
        }
    }
}
