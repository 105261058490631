import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DataTableDirective, DataTablesModule} from "angular-datatables";
import {Observable} from "rxjs";
import {COMPANYDB, CompanyDB} from "../../shared/data/tables/company";
import {NgbDateStruct, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TableService} from "../../shared/services/table.service";
import {HttpClient} from "@angular/common/http";
import {NgbdSortableHeader} from "../../shared/directives/NgbdSortableHeader";
import {SharedModule} from "../../shared/shared.module";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {BackendService} from "../../shared/services/backend.service";
import {NotificationService} from "../../shared/services/notification.service";
import {CONFIG} from "../../../../config";

@Component({
    selector: 'app-users',
    standalone: true,
    imports: [
        SharedModule,
        DataTablesModule,
        NgIf,
        NgForOf
    ],
    templateUrl: './users.component.html',
    styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit {
    @ViewChild(DataTableDirective)
    datatableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, {static: false})
    dtElement!: DataTableDirective;
    public selected = [];
    @ViewChild('content') content: any;
    @ViewChild('depositContent') depositContent: any;
    @ViewChild('changePasswordContent') changePasswordContent: any;
    public tableItem$: Observable<CompanyDB[]>;
    startDate: NgbDateStruct;
    endDate: NgbDateStruct;
    users: any[] = []
    navigation = 'select';
    total$: Observable<number>;
    userForm!: FormGroup;
    persons: any[] = [
        {id: 1, firstName: 'John', lastName: 'Doe', action: 'deposit'},
        {id: 2, firstName: 'Jane', lastName: 'Smith', action: 'withdraw'},
        {id: 3, firstName: 'Alice', lastName: 'Johnson', action: 'toggle'},
        {id: 4, firstName: 'Bob', lastName: 'Brown', action: 'delete'}
    ];
    dataTableParam: any;
    DwTitle: any;
    transactionForm: FormGroup;
    changePasswordForm: FormGroup;
    show: boolean = false;

    constructor(public service: TableService, private fb: FormBuilder,
                private notificationService: NotificationService, private backend: BackendService,
                private http: HttpClient, private modalService: NgbModal) {
        this.userForm = this.fb.group({
            name: ['', Validators.required],
            userName: ['', Validators.required],
            password: ['', Validators.required],
            mainBalance: ['', Validators.required],
            creditReference: [0, Validators.required],
            mobileNo: ['', Validators.required],
            email: ['', Validators.required],
            commission: ['', Validators.required],
            role: ['', Validators.required]
        });
        this.transactionForm = this.fb.group({
            amount: ['', Validators.required],
            userId: [''],
            type: [''],
            password: ['', Validators.required]
        });
        this.changePasswordForm = this.fb.group({
            newPassword: ['', Validators.required],
            userId: [''],
            masterPassword: ['', Validators.required]
        });


        this.tableItem$ = service.tableItem$;
        this.total$ = service.total$;
        this.service.setUserData(COMPANYDB)

    }

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    ngOnInit(): void {
        const that = this;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            searching: true,
            autoWidth: false,
            processing: false,
            order: [0, 'desc'],
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.userList, Object.assign(dataTablesParameters, {})).subscribe(resp => {
                    let data = resp.data.original.data;
                    this.dataTableParam = dataTablesParameters;
                    if (resp.data) {
                        that.users = data;
                    }
                    callback({
                        recordsTotal: resp.data.original.recordsTotal,
                        recordsFiltered: resp.data.original.recordsFiltered,
                        data: []
                    });
                });
            },
            columns: [
                {data: 'createdAt'},
                {data: 'name'},
                {data: 'userName'},
                {data: 'mainBalance'},
                {data: 'role'}
            ]

        };


    }

    openModal() {
        this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            // Handle close/dismiss
        }, (reason) => {
            // Handle close/dismiss
        });
    }

    deposit(person) {

    }

    showPassword: boolean = false;

    withdraw(person) {

    }

    toggleStatus(person) {

    }

    delete(person) {

    }

    onDateChange() {
        // Handle date change logic here
        // console.log('Start Date:', this.startDate);
        // console.log('End Date:', this.endDate);
    }

    onSubmit() {
        let req = {}
        this.backend.createUser(this.userForm.value).subscribe((data: any) => {
            this.notificationService.success(data.meta.message)
            this.closeModal()
            this.userForm.reset()
            this.rerender()

        }, error => {
            this.notificationService.error(error.error.meta.message)

        })
    }

    closeModal() {
        this.modalService.dismissAll(); // This will close all open modals
    }

    rerender(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    openDeposit(w: string, user: any) {
        this.transactionForm.patchValue({
            userId: user._id
        });
        if (w == "D") {
            this.DwTitle = "Deposit"
            this.transactionForm.patchValue({
                type: w
            });

        } else if (w == "W") {
            this.DwTitle = "WithDraw"
            this.transactionForm.patchValue({
                type: w
            });
        }
        this.openDepositModal()
    }

    openDepositModal() {
        this.modalService.open(this.depositContent, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            // Handle close/dismiss
        }, (reason) => {
            // Handle close/dismiss
        });
    }

    onSubmitDw() {
        // console.log(this.transactionForm.value)
        this.backend.addDWTransaction(this.transactionForm.value).subscribe((data: any) => {
            this.notificationService.success(data.meta.message)
            this.transactionForm.reset()
            this.rerender()
            this.closeModal()

        }, error => {
            this.notificationService.error(error.error.meta.message)
            this.transactionForm.reset()


        })
    }

    onSubmitChangePassword() {
        // console.log(this.transactionForm.value)

        this.backend.changeUserPasswordByParent(this.changePasswordForm.value).subscribe((data: any) => {
            this.notificationService.success(data.meta.message)
            this.changePasswordForm.reset()
            this.rerender()
            this.closeModal()

        }, error => {
            // this.notificationService.error(error.meta.message)
            let responseData = error.error;
            this.backend.ErrorNotification_Manager(responseData);
        })
    }

    openPasswordChangeModal(person: any) {
        this.changePasswordForm.patchValue({
            userId: person._id
        });
        this.modalService.open(this.changePasswordContent, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            // Handle close/dismiss
        }, (reason) => {
            // Handle close/dismiss
        });
    }

    showNewPassword: boolean = false;
    showMasterPassword: boolean = false;
    selectedRole: string=localStorage.getItem('role');

    showHideNewPassword() {
        this.showNewPassword = !this.showNewPassword;
    }

    showHideMasterPassword() {
        this.showMasterPassword = !this.showMasterPassword;
    }
}
