import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor() {
    }

    logout(): void {
        localStorage.removeItem('token');
    }

    getToken(): string | null {
        return localStorage.getItem('token');
    }

    isLoggedIn() {
        const token = this.getToken();
        if (token) {
            return true
        } else {
            return false
        }
        // console.log(this.jwtHelper.isTokenExpired(token), "checking expire")
        // return !this.jwtHelper.isTokenExpired(token);
    }
}
