import { Component } from '@angular/core';
import {Data} from "@angular/router";
import {DataShareService} from "../../../../../../shared/services/data-share.service";
export interface Balance {
  icon: string;
  title: string;
  price: string;
  growth: string;
  colorClass: string;
  show?: boolean
}
@Component({
  selector: 'app-right-chart-data',
  templateUrl: './right-chart-data.component.html',
  styleUrls: ['./right-chart-data.component.scss']
})
export class RightChartDataComponent {
   dashboardData!: any;

  constructor(private dataShareService:DataShareService) {
    this.dataShareService.sharedObject$.subscribe(data => {
      this.dashboardData = data;
      // console.log(this.dashboardData); // Use sharedData as needed

    });
  }

  ngOnInit(): void {

  }
  public balance: Balance[] = [
    {
      icon: "fa-thumbs-up",
      title: "Success",
      price: this.dashboardData?.successWithdrawalTxn,
      growth: "456",
      colorClass: "success",
    },
    {
      icon: "fa-thumbs-down",
      title: "Failed",
      price: this.dashboardData?.failWithdrawalTxn,
      growth: "-256",
      colorClass: "danger",
    },
    // {
    //   icon: "doller-return",
    //   title: "TurnOver",
    //   price: this.dashboardData?.totalTurnover,
    //   growth: "",
    //   colorClass: "success",
    // },
  ];

  toggle(item: Balance) {
    item.show = !item.show;
  }


}
