import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {BackendService} from "../../shared/services/backend.service";
import {NotificationService} from "../../shared/services/notification.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    newUser = false;
    // public user: firebase.User;
    loginForm: FormGroup;
    show: boolean = false
    errorMessage: any;

    constructor(private fb: FormBuilder, public router: Router,
                private backend: BackendService, private http: HttpClient, private notificationService: NotificationService) {
        this.loginForm = this.fb.group({
            username: ["", [Validators.required]],
            password: ["", Validators.required],
        });
    }

    ngOnInit() {
    }

    // login() {
    //   // if (this.loginForm.value["email"] == "Test@gmail.com" && this.loginForm.value["password"] == "test123") {
    //   //   let user = {
    //   //     email: "Test@gmail.com",
    //   //     password: "test123",
    //   //     name: "test user",
    //   //   };
    //   //   localStorage.setItem("user", JSON.stringify(user));
    //   //   this.router.navigate(["/simple-page/first-page"]);
    //   // }
    // }
    // login(): Observable<any> {
    //     debugger
    //     const req = {
    //         username: this.loginForm.value["username"],
    //         password: this.loginForm.value["password"]
    //     };
    //
    //     return this.http.post(CONFIG.log3in, req).pipe(
    //         tap((response: any) => {
    //             debugger
    //             const token = response.data.token;
    //             localStorage.setItem('token', token);
    //             this.router.navigate([""]);
    //         }),
    //         catchError((error: any) => {
    //             debugger
    //             // Handle error here
    //             console.error('Login failed:', error);
    //             return throwError(error);
    //         })
    //     );
    // }
    login(): void {
        let req = {
            userName: this.loginForm.value["username"],
            password: this.loginForm.value["password"],
        }
        this.backend.login(req).subscribe((data: any) => {
            if (data.data.accessToken) {
                localStorage.clear();
                localStorage.setItem('token', data.data?.accessToken);
                localStorage.setItem('userName', data.data.userDetail?.userName.toString());
                localStorage.setItem('role', data.data.userDetail?.role.toString());
                this.router.navigate(['/dashboard']);
            }

        })
    }

    showPassword() {
        this.show = !this.show
    }
}
