<div class="row">
  <div [ngClass]="{'col-xxl-12 col-md-7 box-col-7': !isAdmin, 'col-xxl-6 col-md-7 box-col-7': isAdmin}">
    <app-order-board></app-order-board>
  </div>
  <div class="col-xxl-3 col-md-5 col-sm-6 box-col-5" *ngIf="this.role == 'RACHAITA'">
<!--    <app-valuable-customer></app-valuable-customer>-->
    <app-monthly-profits ></app-monthly-profits>
  </div>
  <div class="col-xxl-3 col-md-5 col-sm-6 box-col-5" *ngIf="this.role == 'RACHAITA'">
        <app-valuable-customer></app-valuable-customer>
<!--    <app-monthly-profits></app-monthly-profits>-->
  </div>
<!--  <div class="col-xxl-4 col-sm-6 box-col-6">-->
<!--    <app-monthly-order></app-monthly-order>-->
<!--  </div>-->
<!--  <div class="col-xxl-12 box-col-12">-->
<!--    <app-order-overview></app-order-overview>-->
<!--  </div>-->
  <div class="col-xxl-12 col-md-6 box-col-6">
    <app-visitors></app-visitors>
  </div>

<!--  <div class="col-xxl-3 col-xl-4 col-sm-6 box-col-6 wow zoomIn">-->
<!--    <app-discover-pro></app-discover-pro>-->
<!--  </div>-->
<!--  <div class="col-xxl-4 col-xl-4 col-sm-6 box-col-6">-->
<!--&lt;!&ndash;    <app-visitors></app-visitors>&ndash;&gt;-->
<!--  </div>-->
<!--  <div class="col-xxl-5 col-xl-4 box-col-12">-->
<!--&lt;!&ndash;    <app-recent-orders-table></app-recent-orders-table>&ndash;&gt;-->
<!--  </div>-->
</div>
