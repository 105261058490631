import { Component } from '@angular/core';
import {DashboardModule} from "../dashboard/dashboard.module";

@Component({
  selector: 'app-dashboards',
  standalone: true,
    imports: [
        DashboardModule
    ],
  templateUrl: './dashboards.component.html',
  styleUrl: './dashboards.component.scss'
})
export class DashboardsComponent {

}
