<app-breadcrumb [title]="'Pay-In-Statement'"  [active_item]="'Pay-In-Statement'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="theme-form">
                    <div class="input-group w-50">
                        <div class="input-group-text">From</div>
                        <input class="datepicker-here form-control digits" placeholder="Start Date" name="startDate" [displayMonths]="displayMonths" [navigation]="navigation" [outsideDays]="outsideDays" [showWeekNumbers]="showWeekNumbers" ngbDatepicker #startDatePicker="ngbDatepicker" (click)="startDatePicker.toggle()" [(ngModel)]="startDate" (ngModelChange)="onDateChange()">
                        <div class="input-group-text">To</div>
                        <input class="datepicker-here form-control digits" placeholder="End Date" name="endDate" [displayMonths]="displayMonths" [navigation]="navigation" [outsideDays]="outsideDays" [showWeekNumbers]="showWeekNumbers" ngbDatepicker #endDatePicker="ngbDatepicker" (click)="endDatePicker.toggle()" [(ngModel)]="endDate" (ngModelChange)="onDateChange()">
                    </div>
                </div>
                <div class="card-body p-4">
                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="row-border hover">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Bank name</th>
                                    <th>Acc Holder name</th>
                                    <th>Remarks</th>
                                    <th>Credit/Debit</th>
                                    <th>Date</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="persons?.length != 0">
                                <tr *ngFor="let person of persons">
                                    <td>{{ person.id }}</td>
                                    <td>{{ person.firstName }}</td>
                                    <td>{{ person.lastName }}</td>
                                </tr>
                                </tbody>
                                <tbody *ngIf="persons?.length == 0">
                                <tr>
                                    <td colspan="3" class="no-data-available">No data!</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
