export interface CompanyDB {
  id: number;
  img: string;
  name: string;
  position: string;
  office: string;
  credit: string;
  invoice: string;
  startDate: string;
  salary: string;
  Budget: string;
  ActionClass: string;
  iconClass: string;
  iconColor: string;
}

export const COMPANYDB: CompanyDB[] = [
  {
    id: 1,
    img: "assets/images/user/1.jpg",
    name: "Tiger Nixon",
    position: "System Architect",
    office: "Hewlett packard",
    invoice: "#PX0101",
    startDate: "2022/04/27",
    Budget: "$3142.00",
    salary: "High",
    credit: "4.3%",
    ActionClass: "badge-light-primary",
    iconClass: "icofont-arrow-up",
    iconColor: "success",
  },
  {
    id: 2,
    img: "assets/images/user/2.jpg",
    name: "Garrett Winters",
    position: "Maintenace service",
    office: "Apple Inc.",
    invoice: "#RF304f",
    startDate: "2022/04/22",
    Budget: "$1234.00",
    salary: "Urgent",
    credit: "5.6%",
    ActionClass: "badge-light-danger",
    iconClass: "icofont-arrow-up",
    iconColor: "success",
  },
  {
    id: 3,
    img: "assets/images/user/3.jpg",
    name: "Ashton Cox",
    position: "Junior Technical Author	",
    office: "Edinburgh",
    invoice: "#DNJ480",
    startDate: "2022/05/21",
    Budget: "$2345.00",
    salary: "Low",
    credit: "2.4%",
    ActionClass: "badge-light-success",
    iconClass: "icofont-arrow-down",
    iconColor: "danger",
  },
  {
    id: 4,
    img: "assets/images/user/6.jpg",
    name: "Brielle Williams",
    position: "Senior Javascript Developer",
    office: "Microsoft",
    invoice: "#G189d0",
    startDate: "2022/03/09",
    Budget: "$7689.00",
    salary: "Medium",
    credit: "2.2%",
    ActionClass: "badge-light-warning",
    iconClass: "icofont-arrow-down",
    iconColor: "danger",
  },
  {
    id: 5,
    img: "assets/images/user/7.jpg",
    name: "Tiger Nixon",
    position: "Accountant",
    office: "Tata Co.",
    invoice: "#31D8FFS",
    startDate: "2022/04/10",
    Budget: "$2145.00",
    salary: "High",
    credit: "5.8%",
    ActionClass: "badge-light-primary",
    iconClass: "icofont-arrow-up",
    iconColor: "success",
  },
  {
    id: 6,
    img: "assets/images/user/14.png",
    name: "Michael Morris",
    position: "General service",
    office: "Google Inc.",
    invoice: "#G189D4",
    startDate: "2022/06/12",
    Budget: "$2578.00",
    salary: "Urgent",
    credit: "6.4%",
    ActionClass: "badge-light-danger",
    iconClass: "icofont-arrow-up",
    iconColor: "success",
  },
  {
    id: 7,
    img: "assets/images/user/10.jpg",
    name: "Kevin Dawson",
    position: "System Architect",
    office: "Mindtree Ltd.",
    invoice: "#PX2101",
    startDate: "2022/04/25",
    Budget: "$6538.00",
    salary: "Low",
    credit: "0.3%",
    ActionClass: "badge-light-sucess",
    iconClass: "icofont-arrow-down",
    iconColor: "danger",
  },
  {
    id: 8,
    img: "assets/images/user/12.png",
    name: "Thomas Taylor",
    position: "System Architect",
    office: "Wipro Ltd.",
    invoice: "#px0101",
    startDate: "2022/06/09",
    Budget: "$2121.00",
    salary: "Urgent",
    credit: "7.3%",
    ActionClass: "badge-light-danger",
    iconClass: "icofont-arrow-up",
    iconColor: "success",
  },
  {
    id: 9,
    img: "assets/images/user/14.png",
    name: "Carolyn Jones",
    position: "General service",
    office: "Edinburgh",
    invoice: "#G5384H",
    startDate: "2022/01/11",
    Budget: "$9546.00",
    salary: "High",
    credit: "6.3%",
    ActionClass: "badge-light-primary",
    iconClass: "icofont-arrow-up",
    iconColor: "success",
  },
  {
    id: 10,
    img: "assets/images/user/6.jpg",
    name: "Glen Matney",
    position: "System Architect",
    office: "Mphasis Ltd",
    invoice: "#E5384H",
    startDate: "2022/04/02",
    Budget: "$4587.00",
    salary: "Medium",
    credit: "3.3%",
    ActionClass: "badge-light-warning",
    iconClass: "icofont-arrow-down",
    iconColor: "danger",
  },
  {
    id: 11,
    img: "assets/images/user/9.jpg",
    name: "Charles Kubik",
    position: "System Architect",
    office: "Infosys Ltd.",
    invoice: "#JK384H",
    startDate: "2022/05/01",
    Budget: "$3140.00",
    salary: "Low",
    credit: "2.3%",
    ActionClass: "badge-light-success",
    iconClass: "icofont-arrow-down",
    iconColor: "danger",
  },
  {
    id: 12,
    img: "assets/images/user/10.jpg",
    name: "Herbert Stokes",
    position: "General service",
    office: "Edinburgh",
    invoice: "#HY5384H",
    startDate: "2022/07/04",
    Budget: "$3014.00",
    salary: "Low",
    credit: "1.3%",
    ActionClass: "badge-light-success",
    iconClass: "icofont-arrow-down",
    iconColor: "danger",
  },
  {
    id: 13,
    img: "assets/images/user/11.png",
    name: "Mary Cousar",
    position: "System Architect",
    office: "Infosys.",
    invoice: "#KH384H",
    startDate: "2022/04/06",
    Budget: "$2014.00",
    salary: "Urgent",
    credit: "5.3%",
    ActionClass: "badge-light-danger",
    iconClass: "icofont-arrow-up",
    iconColor: "success",
  },
];
