import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-receipt-card',
  standalone: true,
  imports: [
    DatePipe
  ],
  templateUrl: './receipt-card.component.html',
  styleUrl: './receipt-card.component.scss'
})
export class ReceiptCardComponent {
  @Input() logo: string;
  @Input() heading: string;
  @Input() time: string;
  @Input() paidTo: string;
  @Input() name: string;
  @Input() amount: string;
  @Input() debitReference: string;
  @Input() utrNumber: string;
  @Input() transactionId: string;

}
