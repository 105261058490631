import {Component, OnInit} from "@angular/core";
import {BackendService} from "../../../shared/services/backend.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {DataShareService} from "../../../shared/services/data-share.service";

@Component({
    selector: "app-ecommerce",
    templateUrl: "./ecommerce.component.html",
    styleUrls: ["./ecommerce.component.scss"],
})
export class EcommerceComponent implements OnInit {
    constructor(private backend: BackendService, private dataShareService: DataShareService, private notificationService: NotificationService) {
        this.getDashboardDetails();
    }

    dashboardData: any;

    ngOnInit() {

    }


    getDashboardDetails() {
        this.backend.userDashboard('').subscribe((data: any) => {

            this.dashboardData = data.data;
            // console.log(this.dashboardData)
            this.dataShareService.setSharedObject(this.dashboardData);


        }, error => {
            this.notificationService.error(error.error.meta.message);
        })
    }


}
