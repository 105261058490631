import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as data from '../../../../../shared/data/dashboard/ecommerce'
import {NotificationService} from "../../../../../shared/services/notification.service";
import {BackendService} from "../../../../../shared/services/backend.service";
import {HttpClient} from "@angular/common/http";
import {DataShareService} from "../../../../../shared/services/data-share.service";
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
// (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import * as jspdf from 'jspdf';
import jsPDF from "jspdf";
// const pdf = new jsPDF('p', 'mm', 'a4');

@Component({
    selector: 'app-order-board',
    templateUrl: './order-board.component.html',
    styleUrls: ['./order-board.component.scss']
})
export class OrderBoardComponent implements OnInit {
    balance: any;
    dashboardData: any;
    // @Input() data: any

    role: string;

    constructor(private notification: NotificationService,
                private dataShareService: DataShareService,
                private http: HttpClient, private backend: BackendService) {
    }

    public newOrders = data.newOrders
    public newCustomers = data.newCustomers
    public averageSale = data.averageSale
    public grossProfit = data.grossProfit
    commission: any = data.newCommission;


    ngOnInit() {
        // this.generatePdf()
        this.role = localStorage.getItem('role')
        this.getBalance();
        this.dataShareService.sharedObject$.subscribe(data => {
            this.dashboardData = data;
            // console.log(this.dashboardData); // Use sharedData as needed
            this.newOrders.orders = this.dashboardData?.totalTurnover;
            this.newCustomers.orders = this.dashboardData?.totalWithdrawalTxn;
            this.grossProfit.orders = this.dashboardData?.totalcommission;
            this.averageSale.orders = this.dashboardData?.commissionProfit;
            this.commission.orders = this.dashboardData?.payoutCommission;

            // if (this.role == "PAYOUT") {
            //     newOrders =data.
            // }
        });
    }

    yourLogo: string = 'Your Logo'; // Provide your logo data here


    getBalance() {
        this.dataShareService.sharedBalance$.subscribe(data => {
            this.balance = data
        })
    }
}
