import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";

@Component({
    selector: "app-my-account",
    templateUrl: "./my-account.component.html",
    styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
    public userName: string;
    public profileImg: "assets/images/dashboard/profile.jpg";
     role: string;

    constructor(public router: Router) {
        if (JSON.parse(localStorage.getItem("user"))) {
        } else {
        }
    }

    ngOnInit() {
     this.userName=localStorage.getItem('userName')
     this.role=localStorage.getItem('role')
    }
    showProfileDropdown: boolean = false;

    toggleProfileDropdown() {
        this.showProfileDropdown = !this.showProfileDropdown;
    }
    logoutFunc() {
        localStorage.clear()
        this.router.navigateByUrl('auth/login');
    }

    resetPassword() {

        this.router.navigateByUrl('auth/reset');
    }
}
