<app-breadcrumb [title]="'Users'" [active_item]="'Users'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">

                <div class="p-2">
                    <button class="btn btn-primary "
                            style="float:right" (click)="openModal()"> Add Users
                    </button>
                </div>
                <div class="card-body p-4">

                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="row-border hover">
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>User Name</th>
                                    <th>Balance</th>
                                    <th>Commission</th>
                                    <th>Role</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="persons?.length != 0">
                                <tr *ngFor="let person of users; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ person.name }}</td>
                                    <td>{{ person.userName }}</td>
                                    <td>{{ person.mainBalance }}</td>
                                    <td>{{ person?.commission }}</td>
                                    <td>{{ person.role }}</td>

                                    <td class="d-flex   gap-1">
                                        <button class="btn btn-pill btn-primary-gradien" type="button"
                                                data-bs-original-title="" title="" (click)="openDeposit('D', person)">
                                            Deposit
                                        </button>
                                        <button class="btn btn-pill btn-info-gradien" type="button"
                                                data-bs-original-title="" title="" (click)="openDeposit('W', person)">
                                            Withdraw
                                        </button>
                                        <button class="btn btn-pill btn-warning-gradien" type="button"
                                                data-bs-original-title="" title=""
                                                (click)="openPasswordChangeModal( person)">
                                            <i class="icofont icofont-ui-password"></i>
                                        </button>
                                        <div class="media-body text-end icon-state">
                                            <label class="switch">
                                                <input type="checkbox" [ngModel]="person.status" id="{{i}}"
                                                       name="{{i}}" data-bs-original-title="" title=""><span
                                                    class="switch-state"></span>
                                            </label>
                                        </div>

                                        <button class="btn btn-pill btn-danger btn-air-danger btn-danger-gradien"
                                                type="button" data-bs-original-title="" title="">
                                            Delete
                                        </button>
                                    </td>
                                </tr>


                                </tbody>
                                <tbody *ngIf="users?.length == 0">
                                <tr>
                                    <td colspan="3" class="no-data-available">No data!</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add User</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="userForm" class="form theme-form" (ngSubmit)="onSubmit()">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label" for="name">Name</label>
                            <input class="form-control" autocomplete="off" id="name" type="text" formControlName="name"
                                   placeholder="Enter Name" required>
                            <div *ngIf="userForm.get('name').invalid && userForm.get('name').touched"
                                 class="text-danger">
                                Name is required
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label" for="userName">User Name</label>
                            <input class="form-control" id="userName" autocomplete="off" type="text"
                                   formControlName="userName"
                                   placeholder="Enter User Name" required>
                            <div *ngIf="userForm.get('userName').invalid && userForm.get('userName').touched"
                                 class="text-danger">
                                User Name is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="password">Password</label>
                            <input class="form-control" id="password" type="password" formControlName="password"
                                   placeholder="Enter Password" required>
                            <div *ngIf="userForm.get('password').invalid && userForm.get('password').touched"
                                 class="text-danger">
                                Password is required
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="mobileNo">Mobile</label>
                            <input class="form-control" id="mobileNo" type="text" formControlName="mobileNo"
                                   placeholder="Enter Mobile" required>
                            <div *ngIf="userForm.get('mobileNo').invalid && userForm.get('mobileNo').touched"
                                 class="text-danger">
                                Mobile No is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="mainBalance">Main Balance</label>
                            <input class="form-control" id="mainBalance" name="mainBalance" autocomplete="off"
                                   type="number" formControlName="mainBalance"
                                   placeholder="Enter Main Balance" required>
                            <div *ngIf="userForm.get('mainBalance').invalid && userForm.get('mainBalance').touched"
                                 class="text-danger">
                                Main Balance is required
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="email">Email</label>
                            <input class="form-control" id="email" autocomplete="off" type="email"
                                   formControlName="email"
                                   placeholder="Enter Email" required>
                            <div *ngIf="userForm.get('email').invalid && userForm.get('email').touched"
                                 class="text-danger">
                                Email is required
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="commission">Commission</label>
                            <input class="form-control" id="commission" type="number" formControlName="commission"
                                   placeholder="Enter Commission" required>
                            <div *ngIf="userForm.get('commission').invalid && userForm.get('commission').touched"
                                 class="text-danger">
                                Commission is required
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="role">Role</label>
                            <select class="form-control" id="role" formControlName="role" required>
                                <option value="">
                                    Select Role</option>
                                <option *ngIf="selectedRole == 'RACHAITA'" value="ADMIN">
                                    ADMIN</option>
                                <option *ngIf="selectedRole == 'RACHAITA'" value="AGENT">
                                    AGENT</option>
                                <option *ngIf="selectedRole == 'RACHAITA' || selectedRole == 'AGENT'" value="MASTER">
                                    MASTER</option>
                                <option *ngIf="selectedRole == 'RACHAITA' || selectedRole == 'MASTER' " value="PAYIN">
                                    PAYIN</option>
                                <option *ngIf="selectedRole == 'RACHAITA' || selectedRole == 'MASTER'" value="PAYOUT">
                                    PAYOUT</option>
                                <option *ngIf="selectedRole == 'RACHAITA'" value="VIEW">
                                    VIEW</option>
                            </select>
                            <div *ngIf="userForm.get('role').invalid && userForm.get('role').touched"
                                 class="text-danger">
                                Role is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" [disabled]="!userForm.valid">Submit</button>
            </div>
        </form>

    </div>
</ng-template>
<ng-template #depositContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{this.DwTitle}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="transactionForm" (ngSubmit)="onSubmitDw()">
            <div class="mb-3">
                <label for="amount" class="form-label">Amount</label>
                <input type="number" class="form-control" id="amount" formControlName="amount"
                       placeholder="Enter amount">
                <div *ngIf="transactionForm.get('amount').invalid && transactionForm.get('amount').touched"
                     class="text-danger">
                    Amount is required
                </div>
            </div>

            <div class="mb-3">
                <label for="masterPassword" class="form-label">Password</label>
                <div class="input-group">
                    <input [type]="showMasterPassword ? 'text' : 'password'" class="form-control" id="passwords" formControlName="password"
                           placeholder="Enter Master Password">
                    <button class="btn btn-outline-primary" type="button" (click)="showHideMasterPassword()">
                        {{ showMasterPassword ? 'Hide' : 'Show' }}
                    </button>
                </div>
                <div *ngIf="transactionForm.get('password').invalid && transactionForm.get('password').touched"
                     class="text-danger">
                    Master P assword is Required
                </div>
            </div>

            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" [disabled]="!transactionForm.valid">Submit</button>
            </div>
        </form>

    </div>
</ng-template>

<ng-template #changePasswordContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Change User Password</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmitChangePassword()">
            <div class="mb-3">
                <label for="newPassword" class="form-label">New Password</label>
                <div class="input-group">
                    <input [type]="showNewPassword ? 'text' : 'password'" class="form-control" id="newPassword" formControlName="newPassword"
                           placeholder="Enter New Password">
                    <button class="btn btn-outline-primary" type="button" (click)="showHideNewPassword()">
                        {{ showNewPassword ? 'Hide' : 'Show' }}
                    </button>
                </div>
                <div *ngIf="changePasswordForm.get('newPassword').invalid && changePasswordForm.get('newPassword').touched"
                     class="text-danger">
                    New Password is required
                </div>
            </div>

            <div class="mb-3">
                <label for="masterPassword" class="form-label">Master Password</label>
                <div class="input-group">
                    <input [type]="showMasterPassword ? 'text' : 'password'" class="form-control" id="masterPassword" formControlName="masterPassword"
                           placeholder="Enter Master Password">
                    <button class="btn btn-outline-primary" type="button" (click)="showHideMasterPassword()">
                        {{ showMasterPassword ? 'Hide' : 'Show' }}
                    </button>
                </div>
                <div *ngIf="changePasswordForm.get('masterPassword').invalid && changePasswordForm.get('masterPassword').touched"
                     class="text-danger">
                    Master Password is required
                </div>
            </div>

            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" [disabled]="!changePasswordForm.valid">Submit</button>
            </div>
        </form>
    </div>
</ng-template>




