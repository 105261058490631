import {Component, ElementRef, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DataTableDirective, DataTablesModule} from "angular-datatables";
import {Observable} from "rxjs";
import {COMPANYDB, CompanyDB} from "../../shared/data/tables/company";
import {TableService} from "../../shared/services/table.service";
import {HttpClient} from "@angular/common/http";
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgbdSortableHeader} from "../../shared/directives/NgbdSortableHeader";
import {SharedModule} from "../../shared/shared.module";
import {AbstractControl, FormBuilder, ValidatorFn, Validators} from "@angular/forms";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {NgxDropzoneModule} from 'ngx-dropzone';
import {BackendService} from "../../shared/services/backend.service";
import {NotificationService} from "../../shared/services/notification.service";
import {BASE_URL_API, CONFIG} from "../../../../config";
import {saveAs} from 'file-saver';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
    ReceiptCardComponent
} from "../dashboard/ecommerce/left-content/order-board/receipt-card/receipt-card.component";

@Component({
    selector: 'app-pay-out',
    standalone: true,
    imports: [
        SharedModule,
        DataTablesModule,
        NgxDropzoneModule,
        NgIf,
        NgForOf,
        DatePipe,
        ReceiptCardComponent,
    ],
    templateUrl: './pay-out.component.html',
    styleUrl: './pay-out.component.scss'
})
export class PayOutComponent {
    files: File[] = [];
    formatedStartDate: any;
    formatedEndDate: any;
    @ViewChild('content') content: any;

    disabled = true;
    selectedValue: any = "Success";

    @ViewChild(DataTableDirective)
    datatableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, {static: false})
    dtElement!: DataTableDirective;
    public selected = [];

    displayMonths = 1;
    navigation = 'select';
    outsideDays = 'visible';
    showWeekNumbers = true; // Define the showWeekNumbers property here
    transactionList: any[] = [];
    public tableItem$: Observable<CompanyDB[]>;
    public searchText;
    total$: Observable<number>;
    persons: any[] = [

        {date: '2024-03-01', credit: 1000, debit: 0, closing: 1000, description: 'Salary', fromTo: 'Employer/asdf'},
        {date: '2024-03-05', credit: 0, debit: 500, closing: 500, description: 'Rent', fromTo: 'Landlord/gfgh'},
        {date: '2024-03-10', credit: 200, debit: 0, closing: 700, description: 'Bonus', fromTo: 'Company/wert'},
        // Add more dummy data as needed
    ];
    transactionForm: any;
    dataTableParam: any;
    transactionDetails: any;
    sampleFileData: any;
    selectedStatus: any = "";

    constructor(public service: TableService,
                private modalService: NgbModal, private formBuilder: FormBuilder, private backend: BackendService,
                private http: HttpClient,
                private notificationService: NotificationService,
                private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
        this.tableItem$ = service.tableItem$;
        this.total$ = service.total$;
        this.service.setUserData(COMPANYDB)
        // this.fromDate = calendar.getToday();
        // this.toDate = calendar.getNext(calendar.getToday(), "d", 10);

    }

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    ngOnInit(): void {
        this.currentRole = localStorage.getItem('role');

        const currentDate = new Date();
        let previousDay = currentDate.getDate() - 1;

        if (previousDay === 0) {
            const lastDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();

            previousDay = lastDayOfPreviousMonth;
            this.startDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth(), previousDay);
        } else {
            this.startDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, previousDay);
        }


        this.endDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
        this.formatedStartDate = this.formatDate(this.startDate);
        this.formatedEndDate = this.formatDate(this.endDate);
        const that = this;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            searching: true,
            autoWidth: false,
            order: [0, 'desc'],
            processing: false,
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.withdrawalTransactionList, Object.assign(dataTablesParameters, {
                            status: this.selectedStatus,
                            startDate: this.formatedStartDate,
                            endDate: this.formatedEndDate
                        })).subscribe(resp => {

                    let data = resp.data.original.data;
                    this.dataTableParam = dataTablesParameters;
                    data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
                    that.transactionList = data;

                    callback({
                        recordsTotal: resp.data.original.recordsTotal,
                        recordsFiltered: resp.data.original.recordsFiltered,
                        data: []
                    });
                }, error => {
                    let responseData = error.error;
                    this.backend.ErrorNotification_Manager(responseData);
                });
            },
            columns: [
                {data: 'createdAt'},
                {data: 'transactionReference'},
                {data: 'utr'},
                {data: 'accountHolderName'},
                {data: 'accountNumber'},
                {data: 'amount'},
                {data: 'remark'},
                {data: 'paymentStatus'}
            ]
        };
        this.transactionForm = this.formBuilder.group({
            accountNumber: ['', [Validators.required, this.noDecimal(), Validators.pattern(/^\d+$/)]], // Only digits allowed
            accountHolderName: ['', [Validators.required, this.noSpecialCharacters()]],
            ifsc: ['', Validators.required],
            amount: ['', [Validators.required, this.noDecimal(), Validators.pattern(/^\d+$/)]], // Only digits allowed
            paymentMode: ['IMPS', Validators.required],
            remark: ['']
        });
        this.transactionForm.valueChanges.subscribe(value => {
            this.transactionForm.patchValue({
                accountHolderName: value.accountHolderName ? value.accountHolderName.toUpperCase() : null,
                ifsc: value.ifsc ? value.ifsc.toUpperCase() : null,
                // Add more form controls here if needed
            }, {emitEvent: false}); // Prevent infinite loop by not emitting the event
        });
        const accountNumberInput = document.getElementById('accNumber') as HTMLInputElement;
        accountNumberInput?.addEventListener('input', this.validateNumberInput.bind(this));

    }


    delete(person) {

    }

    startDate: NgbDate;
    endDate: NgbDate;

    // Other properties and methods

    onDateChange() {
        this.formatedStartDate = this.formatDate(this.startDate);
        this.formatedEndDate = this.formatDate(this.endDate);
        // console.log('Start Date:', this.formatedStartDate);
        // console.log('End Date:', this.formatedEndDate);
    }

    selectedCard: string = '';
    currentRole: string;
    loading: boolean = false;

    showCard(cardId: string): void {
        if (this.selectedCard === cardId) {
            this.selectedCard = ''
        } else {
            this.selectedCard = cardId;

        }


    }


    downloadSampleSheet() {
        // this.backend.downloadSampleFile().subscribe((data: any) => {
        //     this.sampleFileData = data;
        //
        // }, error => {
        //     let responseData = error.error;
        //     this.backend.ErrorNotification_Manager(responseData);
        // })
        this.http.get(`${BASE_URL_API}/app/wallet/users/downloadSampleFile`, {responseType: 'blob'}).subscribe((data: Blob) => {
            // Create a blob URL for the blob data
            const file = new Blob([data], {type: 'application/octet-stream'});
            const fileURL = URL.createObjectURL(file);

            // Use file-saver to initiate the download
            saveAs(fileURL, 'sample_sheet.xlsx');

            URL.revokeObjectURL(fileURL);
        }, error => {
            // Handle error
            // console.error('Error downloading sample sheet:', error);
        });
    }


    onSelect(event: any) {
        // this.files.push(...event.addedFiles);
        const file = event.addedFiles[0];
        if (file) {
            this.uploadFile(file)
        }
    }


    uploadFile(file: any) {
        this.loading = true;
        const formData = new FormData();
        formData.append('transactionsFile', file);
        this.backend.uploadSampleFile(formData).subscribe(data => {
            this.loading = false;
            this.notificationService.success(data.meta.message)
        })
    }

    onRemove(file: File) {
        this.files = this.files.filter(f => f !== file);
    }

    addSingle() {
        this.loading = true;
        this.backend.addWithdrawalTransaction(this.transactionForm.value).subscribe((data: any) => {
            this.notificationService.success(data.meta.message)
            this.loading = false;
            this.transactionForm.reset()
            this.transactionForm.patchValue({
                paymentMode: 'IMPS'
            });
            // this.transactionForm.conro
            this.rerender();
        }, error => {
            this.loading = false;
            let responseData = error.error;
            this.backend.ErrorNotification_Manager(responseData);
        })
    }

    clearForm() {
        this.transactionForm.reset()
        this.transactionForm.patchValue({
            paymentMode: 'IMPS'
        });
    }

    rerender(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }


    validateNumberInput(event: Event) {
        const inputValue = (event.target as HTMLInputElement).value;
        if (inputValue.includes('.')) {
            // Decimal point found, clear the input value
            (event.target as HTMLInputElement).value = inputValue.replace('.', '');
        }
    }

    noSpecialCharacters(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const regex = /^[a-zA-Z0-9 ]*$/; // Regular expression to allow only alphanumeric characters and spaces
            const isValid = regex.test(control.value);
            return isValid ? null : {'specialCharacters': true};
        };
    }

    noDecimal(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const isValid = Number.isInteger(Number(control.value));
            return isValid ? null : {'decimalNotAllowed': false};
        };
    }

    openModal() {
        this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            // Handle close/dismiss
        }, (reason) => {
            // Handle close/dismiss
        });
    }

    getDetails(item: any) {
        let req = {
            id: item._id
        }
        this.backend.withdrawalTransactionDetails(req).subscribe((data: any) => {
            this.transactionDetails = data.data;
            this.transactionTime=this.transactionDetails.paymentDateTime
            this.name= this.transactionDetails.accountHolderName; // Paid to
            this.amount=this.transactionDetails.amount ; // Amount
            this.debitReference = 'ABC123'; // Debit reference
            this.utrNumber = this.transactionDetails.paymentMode; // UTR number
            this.transactionId = this.transactionDetails.transactionReference;
            this.openModal()
        }, error => {
            let responseData = error.error;
            this.backend.ErrorNotification_Manager(responseData);
        })
    }

    formatDate(date: any) {

        if (date) {
            const year = date.year.toString().padStart(4, '0');
            const month = date.month.toString().padStart(2, '0');
            const day = date.day.toString().padStart(2, '0');
            return `${year}-${month}-${day}T00:00:00.000Z`;
        }
        return null;
    }

    onSelectionChange() {
        // console.log(this.selectedStatus)
        this.rerender()
    }


    yourLogo: string = ''; // Transaction heading
    transactionHeading: string = 'Transaction Successful'; // Transaction heading
    transactionTime: string = 'Time: 24:23'; // Transaction time
    paidTo: string = 'John Doe'; // Paid to
    name: string = 'John Doe'; // Name
    amount: string = '$100'; // Amount
    debitReference: string = 'ABC123'; // Debit reference
    utrNumber: string = '1234567890'; // UTR number
    transactionId: string = '07987987987'; // Transaction ID
    showReceipt:boolean=false;
    @ViewChild('receiptContent') receiptContent: ElementRef;

    downloadPDFHTML() {
        const data = this.receiptContent.nativeElement;

        html2canvas(data, { scale: 2 }).then(canvas => {
            const imgWidth = 150; // Half of A4 width (210 / 2)
            const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate height based on aspect ratio
            const pdf = new jsPDF('p', 'mm', [150, imgHeight]); // Half of A4 size
            const contentDataURL = canvas.toDataURL('image/png');
            const position = 0;

            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            pdf.save(`${this.transactionDetails.remark || 'Phoni Pay'}.pdf`);
        });
    }


}
