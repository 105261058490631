import {Component, OnInit} from "@angular/core";
import * as chartData from "../../../../../shared/data/dashboard/ecommerce";
import {DataShareService} from "../../../../../shared/services/data-share.service";

@Component({
    selector: "app-visitors",
    templateUrl: "./visitors.component.html",
    styleUrls: ["./visitors.component.scss"],
})
export class VisitorsComponent implements OnInit {
    public visitors = chartData.visitors;
    public show: boolean = false;
    dashboardData: any;
    monthlyTurnoverArray: any[];
    monthlyTransactionArray: any[];

    constructor(private dataShareService: DataShareService) {
        setTimeout(() => {
            this.getChartData();
        }, 3000);
    }

    ngOnInit() {

    }

    getChartData() {
        this.dataShareService.sharedObject$.subscribe(data => {
            this.dashboardData = data;
            this.monthlyTurnoverArray = Object.keys(this.dashboardData.totalMonthlyTurnover.monthly).map(month => this.dashboardData.totalMonthlyTurnover.monthly[month]);
            this.monthlyTransactionArray = Object.keys(data.totalMonthlyWdTxn.monthly).map(month => data.totalMonthlyWdTxn.monthly[month]);
            if (this.visitors?.series[0]?.data) {

                this.visitors.series[0].data = this.monthlyTurnoverArray;
            }
            if (this.visitors?.series[1]?.data) {

                this.visitors.series[1].data = this.monthlyTransactionArray;
            }

        })
    }

    toggle() {
        this.show = !this.show;
    }
}
