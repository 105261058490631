<div class="alerts-container" style="position: absolute; top: 0; right: 0; width: 20%; z-index: 1000;">
    <div *ngFor="let alert of alerts" class="alert-wrapper">
        <ngb-alert
                [type]="alert.type"
                (closed)="close(alert)"
                [class.success]="alert.type === 'success'"
                [class.error]="alert.type === 'error'"
        >
            {{ alert.message }}
        </ngb-alert>
    </div>
</div>
