import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {CONFIG} from "../../../../config";
import {NotificationService} from "./notification.service";

@Injectable({
    providedIn: 'root'
})
export class BackendService {
    private selectedNumbers: { [key: string]: number } = {};

    constructor(
        private http: HttpClient, private router: Router, private toastr: NotificationService) {
    }


    ErrorNotification_Manager(responseData: any) {
        if (responseData.meta) {
            let errorObject = responseData.meta.message;
            if (typeof errorObject === 'object') {
                for (var key of Object.keys(errorObject)) {
                    this.toastr.error(errorObject[key].message)
                    return;
                }
            } else {
                this.toastr.error(errorObject[key]?.message)
                return;
            }

        } else {
            this.toastr.error('Hey, looks like something went wrong.',)
            return;
        }
    }

    getUniqueIdTimestamp() {
        var date = new Date();
        var components = [
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        ];
        var id = components.join("");
        return id;
    }

    userList(req: any): Observable<any> {
        return this.http.post(CONFIG.userList, req)
    }

    userBalance(): Observable<any> {
        return this.http.post(CONFIG.userBalance, '')
    }

    createUser(req: any): Observable<any> {
        return this.http.post(CONFIG.createUser, req)
    }

    addDepositTransaction(req: any): Observable<any> {
        return this.http.post(CONFIG.addDepositTransaction, req)
    }

    addWithdrawalTransaction(req: any): Observable<any> {
        return this.http.post(CONFIG.addWithdrawalTransaction, req)
    }

    changeUserPassword(req: any): Observable<any> {
        return this.http.post(CONFIG.changeUserPassword, req)
    }

    userPayoutStatement(req: any): Observable<any> {
        return this.http.post(CONFIG.userPayoutStatement, req)
    }

    userAccountStatement(req: any): Observable<any> {
        return this.http.post(CONFIG.userAccountStatement, req)
    }

    addDWTransaction(req: any): Observable<any> {
        return this.http.post(CONFIG.addDWTransaction, req)
    }

    changeUserPasswordByParent(req: any): Observable<any> {
        return this.http.post(CONFIG.changeUserPasswordByParent, req)
    }

    withdrawalTransactionDetails(req: any): Observable<any> {
        return this.http.post(CONFIG.withdrawalTransactionDetails, req)
    }

    userDashboard(req: any): Observable<any> {
        return this.http.post(CONFIG.userDashboard, req)
    }

    downloadSampleFile(): Observable<any> {
        return this.http.get(CONFIG.downloadSampleFile)
    }


    uploadSampleFile(parms: any): Observable<any> {
        return this.http.post(CONFIG.uploadSampleFile, parms)
    }

    login(parms: any): Observable<any> {
        return this.http.post(CONFIG.login, parms)
    }


}
