import {Component, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Observable} from "rxjs";
import {COMPANYDB, CompanyDB} from "../../shared/data/tables/company";
import {TableService} from "../../shared/services/table.service";
import {SharedModule} from "../../shared/shared.module";
import {AsyncPipe, DatePipe, NgForOf, NgIf} from "@angular/common";
import {NgbdSortableHeader, SortEvent} from "../../shared/directives/NgbdSortableHeader";
import {DataTableDirective, DataTablesModule} from "angular-datatables";
import {HttpClient} from "@angular/common/http";
import {NgbDateStruct, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {content} from "../../shared/routes/routes";

class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Component({
    selector: 'app-pay-in',
    standalone: true,
    imports: [
        SharedModule,
        AsyncPipe,
        DataTablesModule,
        DatePipe,
        NgForOf,
        NgIf
    ],
    templateUrl: './pay-in.component.html',
    styleUrl: './pay-in.component.scss'
})
export class PayInComponent {
    @ViewChild(DataTableDirective)
    datatableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, {static: false})
    dtElement!: DataTableDirective;
    public selected = [];
    @ViewChild('content') content: any;
    public tableItem$: Observable<CompanyDB[]>;
    public searchText;
    startDate: NgbDateStruct;
    endDate: NgbDateStruct;
    displayMonths = 2;
    navigation = 'select';
    outsideDays = 'visible';
    showWeekNumbers = true; // Define the showWeekNumbers property here
    total$: Observable<number>;
    persons: any[] = [
        { id: 1, firstName: 'John', lastName: 'Doe', action: 'deposit' },
        { id: 2, firstName: 'Jane', lastName: 'Smith', action: 'withdraw' },
        { id: 3, firstName: 'Alice', lastName: 'Johnson', action: 'toggle' },
        { id: 4, firstName: 'Bob', lastName: 'Brown', action: 'delete' }
    ];

    constructor(public service: TableService, private http: HttpClient, private modalService: NgbModal) {


        this.tableItem$ = service.tableItem$;
        this.total$ = service.total$;
        this.service.setUserData(COMPANYDB)

    }

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
    preVoidList: any[] = []


    onSort({column, direction}: SortEvent) {
        console.log('sorting')
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.service.sortColumn = column;
        this.service.sortDirection = direction;

    }

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 2,
            serverSide: true,
            processing: false,
            data:[],
            columns: [
                { data: 'id' },
                { data: 'UpiId' },
                { data: 'accountHolderName' },
                { data: 'bankName' },
                { data: 'balance' },
                { data: 'action' } // Include action column
            ]
        };
    }

    openModal() {
        this.modalService.open(this.content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            // Handle close/dismiss
        }, (reason) => {
            // Handle close/dismiss
        });
    }

    deposit(person) {
        
    }

    withdraw(person) {
        
    }

    toggleStatus(person) {
        
    }

    delete(person) {
        
    }
    onDateChange() {
        // Handle date change logic here
        console.log('Start Date:', this.startDate);
        console.log('End Date:', this.endDate);
    }
}
