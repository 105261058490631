<!--<div class="media profile-media">-->
<!--  <img class="b-r-10" src="assets/images/dashboard/profile.png" alt="">-->
<!--  <div class="media-body"><span>{{userName}}</span>-->
<!--    <p class="mb-0 font-roboto">{{role}} <i class="middle fa fa-angle-down"></i></p>-->
<!--  </div>-->
<!--</div>-->
<!--<ul class="profile-dropdown onhover-show-div">-->
<!--  <li><a (click)="resetPassword()"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>-->
<!--&lt;!&ndash;  <li><a href="javascript:void(0)"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>&ndash;&gt;-->
<!--&lt;!&ndash;  <li><a href="javascript:void(0)"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>&ndash;&gt;-->
<!--  <li><a (click)="resetPassword()"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Change Password</span></a></li>-->
<!--  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>-->
<!--</ul>-->
<div class="media profile-media" (click)="toggleProfileDropdown()">
  <img class="b-r-10" src="assets/images/dashboard/profile.png" alt="">
  <div class="media-body mt-2"><span>{{userName}}</span>
    <p class="mb-0 font-roboto">{{role}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div" *ngIf="showProfileDropdown">
  <li><a (click)="resetPassword()"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <!-- Other dropdown items go here -->
  <li><a (click)="resetPassword()"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Change Password</span></a></li>
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>
</ul>
