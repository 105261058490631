import { Component } from '@angular/core';

@Component({
  selector: 'app-discover-pro',
  templateUrl: './discover-pro.component.html',
  styleUrls: ['./discover-pro.component.scss']
})
export class DiscoverProComponent {

}
