import {Routes} from "@angular/router";
import {PayInComponent} from "../../components/pay-in/pay-in.component";
import {StatementComponent} from "../../components/statement/statement.component";
import {PayInStatementComponent} from "../../components/pay-in-statement/pay-in-statement.component";
import {DashboardsComponent} from "../../components/dashboards/dashboards.component";
import {PayOutComponent} from "../../components/pay-out/pay-out.component";
import {UsersComponent} from "../../components/users/users.component";
import {PayOutStatementComponent} from "../../components/pay-out-statement/pay-out-statement.component";


function DashboardGuard() {
    const userRole = localStorage.getItem('role');

    if (userRole !== 'VIEW') {
        return true;
    } else {
        return false
    }
}

function PayinGuard() {
    const userRole = localStorage.getItem('role');

    if (userRole == 'RACHAITA' || userRole === 'PAYIN') {
        return true;
    } else {
        return false
    }
}
function PayoutGuard() {
    const userRole = localStorage.getItem('role');

    if (userRole == 'RACHAITA' || userRole === 'PAYOUT') {
        return true;
    } else {
        return false
    }
}
function UsersGuard() {
    const userRole = localStorage.getItem('role');

    if (userRole == 'RACHAITA' || userRole === 'AGENT' || userRole == 'MASTER') {
        return true;
    } else {
        return false
    }
}

export const content: Routes = [

    //
    // <option value="ADMIN"> All ... {agent , view} while creation
    //     <option value="AGENT"> Dashboard,Statement, User
    //     <option value="MASTER"> Dashboard,Statement, User ... {payIn , payOut} while creation
    //     <option value="PAYIN"> Dashboard,Statement, PayIn, PayInStatement
    //     <option value="PAYOUT"> Dashboard,Statement, PayOut, PayOUtStatement
    //     <option value="VIEW"> View Transaction,
    {
        path: '',
        redirectTo: 'dashboard'
    }, {
        path: 'dashboard',
        component: DashboardsComponent,
        canActivate: [DashboardGuard]
    },
    {
        path: 'pay-in',
        component: PayInComponent,
        canActivate: [PayinGuard]
    }, {
        path: 'pay-in-statement',
        component: PayInStatementComponent,
        canActivate: [PayinGuard]
    }, {
        path: 'statement',
        component: StatementComponent,
        canActivate: [DashboardGuard]
    }, {
        path: 'pay-out',
        component: PayOutComponent,
        canActivate: [PayoutGuard]
    },  {
        path: 'pay-out-statement',
        component: PayOutStatementComponent,
        canActivate: [PayoutGuard]
    }, {
        path: 'users',
        component: UsersComponent,
        canActivate: [UsersGuard]
    }
];

