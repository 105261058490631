<app-breadcrumb [title]="'Dashboard'" [active_item]="'Dashboard'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row size-column">
        <div class="col-xxl-12 col-md-12 box-col-8 grid-ed-12">
            <app-left-content></app-left-content>
        </div>
        <!--    <div class="col-xxl-2 col-xl-3 col-md-4 grid-ed-none box-col-4e d-xxl-block d-none">-->
        <!--      <app-right-top-categories></app-right-top-categories>-->
        <!--    </div>-->
    </div>
</div>
