<div class="page-wrapper">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-12">
                <div class="login-card login-dark">
                    <div>
                        <div>
                            <a class="logo"  routerLink='/'>
                                <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage" style="max-width: 40%">
                                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" style="max-width: 40%">
                            </a>
                        </div>
                        <div class="login-main">
                            <form [formGroup]="passwordForm" class="theme-form" (ngSubmit)="onSubmit()">
                                <h4>Create Your Password</h4>
                                <div class="form-group">
                                    <label class="col-form-label">Old Password</label>
                                    <input class="form-control" formControlName="oldPassword" type="password" required>
                                    <div *ngIf="passwordForm.get('oldPassword').invalid && passwordForm.get('oldPassword').touched" class="text-danger">
                                        Old Password is required
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">New Password</label>
                                    <input class="form-control" formControlName="newPassword" type="password" required>
                                    <div *ngIf="passwordForm.get('newPassword').invalid && passwordForm.get('newPassword').touched" class="text-danger">
                                        New Password is required and must be at least 6 characters long
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">Retype Password</label>
                                    <input class="form-control" formControlName="confirmPassword" type="password" required>
                                    <div *ngIf="passwordForm.hasError('mismatch') && passwordForm.get('confirmPassword').touched" class="text-danger">
                                        Passwords do not match
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <button class="btn btn-primary d-block w-100" type="submit" [disabled]="!passwordForm.valid">Done</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
