import {Component, OnInit} from '@angular/core';
import {NotificationService} from "../../../../shared/services/notification.service";
import {DataShareService} from "../../../../shared/services/data-share.service";
import {HttpClient} from "@angular/common/http";
import {BackendService} from "../../../../shared/services/backend.service";

@Component({
    selector: 'app-left-content',
    templateUrl: './left-content.component.html',
    styleUrls: ['./left-content.component.scss']
})
export class LeftContentComponent implements OnInit {
    role: string;
     isAdmin: boolean=false;

    constructor(private notification: NotificationService,
                private dataShareService: DataShareService,
                private http: HttpClient, private backend: BackendService) {
    }

    ngOnInit() {
        this.role = localStorage.getItem('role')
        if (this.role === 'RACHAITA') {
            this.isAdmin = true;
        }
    }

}
