import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ChartistModule } from "ng-chartist";
import { NgChartsModule } from "ng2-charts";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgApexchartsModule } from "ng-apexcharts";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../../shared/shared.module";
import { DashboardRoutingModule } from "./dashboard-routing.module";

import { EcommerceComponent } from "./ecommerce/ecommerce.component";
import { LeftContentComponent } from "./ecommerce/left-content/left-content.component";
import { RightTopCategoriesComponent } from "./ecommerce/right-top-categories/right-top-categories.component";
import { OrderBoardComponent } from "./ecommerce/left-content/order-board/order-board.component";
import { ValuableCustomerComponent } from "./ecommerce/left-content/valuable-customer/valuable-customer.component";
import { MonthlyOrderComponent } from "./ecommerce/left-content/monthly-order/monthly-order.component";
import { MonthlyProfitsComponent } from "./ecommerce/left-content/monthly-profits/monthly-profits.component";
import { OrderOverviewComponent } from "./ecommerce/left-content/order-overview/order-overview.component";
import { DiscoverProComponent } from "./ecommerce/left-content/discover-pro/discover-pro.component";
import { VisitorsComponent } from "./ecommerce/left-content/visitors/visitors.component";
import { RightChartDataComponent } from './ecommerce/left-content/order-overview/right-chart-data/right-chart-data.component';
import { SaleStatusComponent } from './ecommerce/left-content/order-board/sale-status/sale-status.component';
import {ReceiptCardComponent} from "./ecommerce/left-content/order-board/receipt-card/receipt-card.component";
@NgModule({
  declarations: [
    EcommerceComponent,
    LeftContentComponent,
    RightTopCategoriesComponent,
    OrderBoardComponent,
    ValuableCustomerComponent,
    MonthlyOrderComponent,
    MonthlyProfitsComponent,
    OrderOverviewComponent,
    DiscoverProComponent,
    VisitorsComponent,
    RightChartDataComponent,

    SaleStatusComponent,
  ],
    imports: [CommonModule, ChartistModule, CarouselModule, NgChartsModule, NgApexchartsModule, SharedModule, NgbModule, FormsModule, DashboardRoutingModule, ReceiptCardComponent],
    exports: [

        SaleStatusComponent,
        EcommerceComponent
    ]
})
export class DashboardModule {}
