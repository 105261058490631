
// const BASE_URL_API = 'http://3.108.242.191:4320';
export const BASE_URL_API = ' ';

export const CONFIG = {
    login: BASE_URL_API + "/app/user/userLogin",
    createUser: BASE_URL_API + "/app/user/createUser",
    userList: BASE_URL_API + "/app/user/userList",
    userBalance: BASE_URL_API + "/app/user/userBalance",
    addWithdrawalTransaction: BASE_URL_API + "/app/wallet/users/addWithdrawalTransaction",
    withdrawalTransactionList: BASE_URL_API + "/app/wallet/users/withdrawalTransactionList",
    addDepositTransaction: BASE_URL_API + "/app/wallet/users/addDepositTransaction",
    withdrawalTransactionDetails: BASE_URL_API + "/app/wallet/users/withdrawalTransactionDetails",
    addDWTransaction: BASE_URL_API + "/app/wallet/users/addDWTransaction",
    changeUserPassword: BASE_URL_API + "/app/user/changeUserPassword",
    userAccountStatement: BASE_URL_API + "/app/user/userAccountStatement",
    changeUserPasswordByParent: BASE_URL_API + "/app/user/changeUserPasswordByParent",
    userPayoutStatement: BASE_URL_API + "/app/user/userPayoutStatement",
    userDashboard: BASE_URL_API + "/app/user/userDashboard",
    downloadSampleFile: BASE_URL_API + "/app/wallet/users/downloadSampleFile",
    uploadSampleFile: BASE_URL_API + "/app/wallet/users/importTransactionsExcel",
}
