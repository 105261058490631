import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest} from '@angular/common/http';
import {catchError, finalize, Observable, throwError} from 'rxjs';
import {AuthService} from './auth.service';
import {Router} from "@angular/router";
import {NotificationService} from "./notification.service";

@Injectable({
    providedIn: 'root'
})
export class InterceptorService {
    token: any;

    constructor(
        private authService: AuthService,
        // private spinner: NgxSpinnerService,
        public toastr: NotificationService,
        public router: Router,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authToken = this.authService.getToken();
        if (req.url.includes('/login')) {
            // this.spinner.hide();
            return next.handle(req);
        }
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${authToken}`,

        });
        const authReq = req.clone({headers});
        return next.handle(authReq).pipe(
            finalize(() => {
                // this.spinner.hide(); // Hide the spinner
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    localStorage.clear();
                    this.router.navigate(['/auth/login'])
                }
                // Handle the error here
                console.log(error.error)
                this.toastr.error(error?.error.meta?.message);
                return throwError(error);
            })
        );
    }
}
