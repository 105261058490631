import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DataShareService {
    private sharedObjectSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public sharedObject$: Observable<any> = this.sharedObjectSubject.asObservable();


    private sharedBalanceSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public sharedBalance$: Observable<any> = this.sharedBalanceSubject.asObservable();


    private sharedRoleSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public sharedRole$: Observable<any> = this.sharedRoleSubject.asObservable();

    setSharedObject(obj: any) {
        this.sharedObjectSubject.next(obj);
    }

    setSharedBalance(obj: any) {
        this.sharedBalanceSubject.next(obj);
    }

    setRole(obj: any) {

        this.sharedRoleSubject.next(obj);
    }
}
