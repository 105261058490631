import {Component} from '@angular/core';
import {RouterLink} from "@angular/router";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgIf} from "@angular/common";
import {BackendService} from "../../shared/services/backend.service";
import {NotificationService} from "../../shared/services/notification.service";

@Component({
    selector: 'app-reset-password',
    standalone: true,
    imports: [
        RouterLink,
        ReactiveFormsModule,
        NgIf
    ],
    templateUrl: './reset-password.component.html',
    styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {
    public show: boolean = false;
    passwordForm: FormGroup;

    constructor(private formBuilder: FormBuilder, private backend: BackendService,
                private notificationService: NotificationService) {

    }

    onSubmit() {
        let req={
            oldPassword: this.passwordForm.controls['oldPassword'].value,
            newPassword: this.passwordForm.controls['newPassword'].value,
        }
        this.backend.changeUserPassword(req).subscribe((data: any) => {
            this.notificationService.success(data.meta.message)
            this.passwordForm.reset()
        }, error => {
            this.notificationService.error(error.meta.message)
        })

    }

    ngOnInit() {
        this.passwordForm = this.formBuilder.group({
            oldPassword: ['', Validators.required],
            newPassword: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required]
        }, { validator: this.passwordMatchValidator });
    }
    passwordMatchValidator(group: FormGroup) {
        const newPassword = group.get('newPassword').value;
        const confirmPassword = group.get('confirmPassword').value;
        return newPassword === confirmPassword ? null : { mismatch: true };
    }

    showPassword() {
        this.show = !this.show;
    }

}
