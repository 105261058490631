import {Component, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {SharedModule} from "../../shared/shared.module";
import {DataTableDirective, DataTablesModule} from "angular-datatables";
import {Observable} from "rxjs";
import {COMPANYDB, CompanyDB} from "../../shared/data/tables/company";
import {TableService} from "../../shared/services/table.service";
import {HttpClient} from "@angular/common/http";
import {NgbdSortableHeader, SortEvent} from "../../shared/directives/NgbdSortableHeader";
import {DatePipe, formatDate, NgForOf, NgIf} from "@angular/common";
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {CONFIG} from "../../../../config";

@Component({
    selector: 'app-statement',
    standalone: true,
    imports: [
        SharedModule,
        DataTablesModule,
        NgIf,
        NgForOf,
        DatePipe
    ],
    templateUrl: './statement.component.html',
    styleUrl: './statement.component.scss'
})
export class StatementComponent {
    @ViewChild(DataTableDirective)
    datatableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, {static: false})
    dtElement!: DataTableDirective;
    public selected = [];
    dataTableParam: any;
    statement: any[] = [];
    displayMonths = 1;
    navigation = 'select';
    outsideDays = 'visible';
    showWeekNumbers = true; // Define the showWeekNumbers property here

    public tableItem$: Observable<CompanyDB[]>;
    public searchText;
    total$: Observable<number>;
    persons: any[] = [

        {date: '2024-03-01', credit: 1000, debit: 0, closing: 1000, description: 'Salary', fromTo: 'Employer/asdf'},
        {date: '2024-03-05', credit: 0, debit: 500, closing: 500, description: 'Rent', fromTo: 'Landlord/gfgh'},
        {date: '2024-03-10', credit: 200, debit: 0, closing: 700, description: 'Bonus', fromTo: 'Company/wert'},
        // Add more dummy data as needed
    ];
     formatedStartDate: any;
     formatedEndDate: any;

    constructor(public service: TableService, private http: HttpClient, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
        this.tableItem$ = service.tableItem$;
        this.total$ = service.total$;
        this.service.setUserData(COMPANYDB)
        // this.fromDate = calendar.getToday();
        // this.toDate = calendar.getNext(calendar.getToday(), "d", 10);

    }

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
    preVoidList: any[] = []


    onSort({column, direction}: SortEvent) {
        // console.log('sorting')
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.service.sortColumn = column;
        this.service.sortDirection = direction;

    }

    ngOnInit(): void {
        const currentDate = new Date();
        this.startDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
        this.endDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
        this.formatedStartDate=this.formatDate(this.startDate)
        this.formatedEndDate=this.formatDate(this.endDate)
        const that = this;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            searching: true,
            autoWidth: false,
            processing: false,
            order: [0, 'desc'],

            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.userAccountStatement, Object.assign(dataTablesParameters, {
                            startDate:this.formatedStartDate,
                            endDate:this.formatedEndDate
                        })).subscribe(resp => {
                    let data = resp.data.original.data;
                    this.dataTableParam = dataTablesParameters;
                    if (resp.data) {
                        that.statement = data;
                    }

                    callback({
                        recordsTotal: resp.data.original.recordsTotal,
                        recordsFiltered: resp.data.original.recordsFiltered,
                        data: []
                    });
                });
            },
            columns: [
                {data: 'createdAt'},
                {data: 'withdraw'},
                {data: 'deposit'},
                {data: 'bankBalance'},
                {data: 'remark'},
                {data: 'userName'} // Include action column
            ]
        };
    }

    delete(person) {

    }


    startDate: any;
    endDate: any;

    formatDate(date: any) {
        if (date) {
            const year = date.year.toString().padStart(4, '0');
            const month = date.month.toString().padStart(2, '0');
            const day = date.day.toString().padStart(2, '0');
            return `${year}-${month}-${day}T00:00:00.000Z`;
        }
        return null;
    }
    onDateChange() {
        // Handle date change logic here
        this.formatedStartDate=this.formatDate(this.startDate);
        this.formatedEndDate=this.formatDate(this.endDate);
        // console.log('Start Date:', this.formatedStartDate);
        // console.log('End Date:', this.formatedEndDate);
    }
    rerender(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }
}
