<div class="row" *ngIf="this.role=='RACHAITA'">
    <div class="col-sm-12">
        <div class="card o-hidden" >
            <div class="card-body balance-widget">
                <span class="f-w-500 f-light" >Total Balance</span>
                <h4 class="mb-3 mt-1 f-w-500 mb-0 f-22"><span class="counter">{{balance}}</span><span
                        class="f-light f-14 f-w-400 ms-1"></span></h4>
                <a class="purchase-btn btn btn-primary btn-hover-effect f-w-500">Tap Up Balance</a>
                <div class="mobile-right-img"><img class="left-mobile-img"
                                                   src="assets/images/dashboard-2/widget-img.png" alt=""/><img
                        class="mobile-img" src="assets/images/dashboard-2/mobile.gif" alt="mobile with coin"/></div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card small-widget">
            <!--            <app-sale-status [data]="newOrders"></app-sale-status>-->
            <div class="card-body primary">
                <span class="f-light">Life Time Transaction</span>
                <div class="d-flex align-items-end gap-1">
                    <h4>{{this.dashboardData?.totalWithdrawalTxn}}</h4>
                    <span class="font-primary f-12 f-w-500"
                    ><i class="icon-arrow-up"></i><span>+50%</span></span
                    >
                </div>
                <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#new-order"></use>
                    </svg>
                </div>
            </div>

        </div>
    </div>

    <div class="col-6">
        <div class="card small-widget">
            <!--            <app-sale-status [data]="newCustomers"></app-sale-status>-->

            <div class="card-body warning">
                <span class="f-light">Turn Over</span>
                <div class="d-flex align-items-end gap-1">
                    <h4>{{this.dashboardData?.totalTurnover}}</h4>
                    <span class="font-warning f-12 f-w-500"
                    ><i class="icon-arrow-up"></i><span>0%</span></span>
                </div>
                <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#customers"></use>
                    </svg>
                </div>
            </div>

        </div>
    </div>
    <div class="col-6">
        <div class="card small-widget">
            <!--            <app-sale-status [data]="averageSale"></app-sale-status>-->
            <div class="card-body danger">
                <span class="f-light">Average Sale</span>
                <div class="d-flex align-items-end gap-1">
                    <h4>{{this.dashboardData?.commissionProfit}}</h4>
                    <span class="font-danger f-12 f-w-500"
                    ><i class="icon-arrow-up"></i><span>+50%</span></span
                    >
                </div>
                <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#new-order"></use>
                    </svg>
                </div>
            </div>

        </div>
    </div>
    <div class="col-6">
        <div class="card small-widget">

            <!--            <app-sale-status [data]="grossProfit"></app-sale-status>-->
            <div class="card-body success">
                <span class="f-light">Gross Profit</span>
                <div class="d-flex align-items-end gap-1">
                    <h4>{{this.dashboardData?.totalcommission}}</h4>
                    <span class="font-success f-12 f-w-500"
                    ><i class="icon-arrow-up"></i><span>0%</span></span
                    >
                </div>
                <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#profit"></use>
                    </svg>
                </div>
            </div>

        </div>
    </div>
    <div class="col-12">
        <div class="card small-widget">
            <div class="card-body {{ commission.colorClass }}">
                <span class="f-light">Commission</span>
                <div class="d-flex align-items-end gap-1">
                    <h4>{{ commission.orders }}</h4>
                    <span class="font-{{ commission.colorClass }} f-12 f-w-500"
                    ><i class="icon-arrow-{{ commission.arrow }}"></i><span>{{ commission.orderGrowth }}%</span></span
                    >
                </div>
                <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ commission.icon }}"></use>
                    </svg>
                </div>
            </div>

        </div>
    </div>
</div>


<!--if role is payout-->

<div class="row" *ngIf="this.role=='PAYOUT'">
    <div class="col-6 ">
        <div class="card o-hidden tb ">
            <div class="card-body balance-widget"  (click)="downloadPDFHTML()">
                <span class="f-w-500 f-light">Total Balance</span>
                <h4 class="mb-3 mt-1 f-w-500 mb-0 f-22"><span class="counter">{{balance}}</span><span
                        class="f-light f-14 f-w-400 ms-1"></span></h4>
                <a class="purchase-btn btn btn-primary btn-hover-effect f-w-500">Tap Up Balance</a>
                <div class="mobile-right-img"><img class="left-mobile-img"
                                                   src="assets/images/dashboard-2/widget-img.png" alt=""/><img
                        class="mobile-img" src="assets/images/dashboard-2/mobile.gif" alt="mobile with coin"/></div>
            </div>
        </div>
        <div class="card small-widget">
            <div class="card-body primary">
                <span class="f-light">Life Time Failed Transaction</span>
                <div class="d-flex align-items-end gap-1">
                    <h4>{{this.dashboardData?.failWithdrawalTxn}}</h4>

                </div>
                <div class="bg-gradient">
                    <i class="fa fa-thumbs-down thumb" ></i>
                    <!--                    <i class="fa fa-arrows-h"></i>-->
                </div>
            </div>

        </div>
    </div>
    <div class="col-6">
        <div class="card small-widget">
            <div class="card-body primary">
                <span class="f-light">Life Time Transaction</span>
                <div class="d-flex align-items-end gap-1">
                    <h4>{{this.dashboardData?.totalWithdrawalTxn}}</h4>

                </div>
                <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ 'course-2' }}"></use>
                    </svg>
                </div>
            </div>

        </div>


        <div class="card small-widget">
            <div class="card-body primary">
                <span class="f-light">Life Time Turn Over</span>
                <div class="d-flex align-items-end gap-1">
                    <h4>{{this.dashboardData?.totalTurnover}}</h4>

                </div>
                <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ 'profit' }}"></use>
                        <!--                        bag-->
                    </svg>
                    <!--                    <i class="fa fa-arrows-h"></i>-->
                </div>
            </div>

        </div>
        <div class="card small-widget">
            <div class="card-body {{ commission.colorClass }}">
                <span class="f-light">Commission</span>
                <div class="d-flex align-items-end gap-1">
                    <h4>{{ commission?.payoutCommission }}</h4>
                </div>
                <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ commission.icon }}"></use>
                    </svg>
                </div>
            </div>


        </div>
        <div>
        </div>
        <div class="card small-widget">
            <div class="card-body {{ commission.colorClass }}">
                <span class="f-light">Life Time Success Transaction</span>
                <div class="d-flex align-items-end gap-1">
                    <h4>{{ dashboardData?.successWithdrawalTxn }}</h4>
                </div>
                <div class="bg-gradient">
                    <i class="fa fa-thumbs-up thumb" ></i>
                </div>
            </div>

        </div>

    </div>

</div>


