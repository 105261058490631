<app-breadcrumb [title]="'Pay-Out'" [active_item]="'Pay-Out'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="theme-form d-flex flex-column flex-lg-row justify-content-between">
                    <div class="theme-form m-2 dateSection">
                        <div class="input-group w-60" style="display: flex; gap: 10px;">
                            <div class="input-group-text">From</div>
                            <input class="datepicker-here form-control digits" placeholder="Start Date" name="startDate"
                                   [displayMonths]="displayMonths" [navigation]="navigation" [outsideDays]="outsideDays"
                                   [showWeekNumbers]="showWeekNumbers" ngbDatepicker #startDatePicker="ngbDatepicker"
                                   (click)="startDatePicker.toggle()" [(ngModel)]="startDate"
                                   (ngModelChange)="onDateChange()">
                            <div class="input-group-text" style="margin-left: 5px">To</div>
                            <input class="datepicker-here form-control digits" placeholder="End Date" name="endDate"
                                   [displayMonths]="displayMonths" [navigation]="navigation" [outsideDays]="outsideDays"
                                   [showWeekNumbers]="showWeekNumbers" ngbDatepicker #endDatePicker="ngbDatepicker"
                                   (click)="endDatePicker.toggle()" [(ngModel)]="endDate"
                                   (ngModelChange)="onDateChange()">
                        </div>
                        <button class="btn btn-primary-gradien searchBtn" (click)="rerender()">Search</button>
                        <div class="d-flex gap-1 mt-1 transfer-buttons">
                            <h5 class="card-title center transfer"
                                style="text-align: center;margin-top: auto;margin-bottom: auto; font-size: 14px; align-items: center">
                                Transfer</h5>
                            <button class="btn btn-primary-gradien" (click)="showCard('singleTransferCard')"
                                    style="font-size: smaller">
                                SINGLE
                            </button>
                            <button class="btn btn-primary-gradien" (click)="showCard('bulkTransferCard')">BULK</button>
                            <button class="btn btn-primary-gradien" (click)="showCard('apiTransferCard')"
                                    *ngIf="this.currentRole == 'RACHAITA' || this.currentRole == 'API' ">API
                            </button>
<!--                            <div class="col text-end form-group " style="margin-top: 0px; width: 10vw;">-->
<!--                                <select style="height: 35px; padding-top: 0; padding-bottom: 0" class="form-control"-->
<!--                                        id="statusFilter" [(ngModel)]="selectedStatus" (change)="onSelectionChange()">-->
<!--                                    <option value="">All</option>-->
<!--                                    <option value="failed">Failed</option>-->
<!--                                    <option value="pending">Pending</option>-->
<!--                                    <option value="initiate">Initiate</option>-->
<!--                                    <option value="success">Success</option>-->
<!--                                </select>-->
<!--                            </div>-->
                            <div class="col text-end form-group" style="margin-top: 0px; width: 10vw;">
                                <select style="padding-top: 7px; padding-bottom: 7px;" class="form-control"
                                        id="statusFilter" [(ngModel)]="selectedStatus" (change)="onSelectionChange()">
                                    <option value="">All</option>
                                    <option value="failed">Failed</option>
                                    <option value="pending">Pending</option>
                                    <option value="initiate">Initiate</option>
                                    <option value="success">Success</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                            </div>

                        </div>

                    </div>
                    <!--                    <div class="d-flex gap-1 mt-1">-->
                    <!--                        <button class="btn btn-primary-gradien" (click)="showCard('singleTransferCard')" style="font-size: smaller">SINGLE-->
                    <!--                            TRANSFER-->
                    <!--                        </button>-->
                    <!--                        <button class="btn btn-primary-gradien" (click)="showCard('bulkTransferCard')">BULK TRANSFER-->
                    <!--                        </button>-->
                    <!--                        <button class="btn btn-primary-gradien" (click)="showCard('apiTransferCard')">API TRANSFER-->
                    <!--                        </button>-->
                    <!--                    </div>-->
                    <div class=" m-1">
                        <div class="p-1">

                        </div>
                    </div>

                </div>

                <div class="card" *ngIf="selectedCard === 'singleTransferCard'">
                    <div class="card-body">
                        <form [formGroup]="transactionForm" (ngSubmit)="addSingle()">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="accNumber">Account Number</label>
                                        <input type="text" class="form-control" formControlName="accountNumber"
                                               id="accNumber" placeholder="Enter account number">
                                        <div *ngIf="transactionForm.get('accountNumber').invalid && transactionForm.get('accountNumber').touched"
                                             class="text-danger">
                                            Account Number is required and must contain only digits.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="accHolderName">Account Holder Name</label>
                                        <input type="text" class="form-control" formControlName="accountHolderName"
                                               id="accHolderName" placeholder="Enter account holder name">
                                        <div *ngIf="transactionForm.get('accountHolderName').invalid && transactionForm.get('accountHolderName').touched"
                                             class="text-danger">
                                            Account Holder Name is required withOut Special Character.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="ifsc">IFSC</label>
                                        <input type="text" class="form-control" formControlName="ifsc" id="ifsc"
                                               placeholder="Enter IFSC">
                                        <div *ngIf="transactionForm.get('ifsc').invalid && transactionForm.get('ifsc').touched"
                                             class="text-danger">
                                            IFSC is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="amount">Amount</label>
                                        <input type="number" class="form-control" formControlName="amount" id="amount"
                                               placeholder="Enter amount">
                                        <div *ngIf="transactionForm.get('amount').invalid && transactionForm.get('amount').touched"
                                             class="text-danger">
                                            Amount is required and must contain only digits.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="paymentType">Payment Type</label>
                                        <select class="form-control" formControlName="paymentMode" id="paymentType">
                                            <option value="" disabled selected hidden>Select payment type</option>
                                            <option value="IMPS" selected>IMPS</option>
                                            <option value="RTGS">RTGS</option>
                                            <option value="NEFT">NEFT</option>
                                        </select>
                                        <div *ngIf="transactionForm.get('paymentMode').invalid && transactionForm.get('paymentMode').touched"
                                             class="text-danger">
                                            Payment Type is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="remark">Remark</label>
                                        <input type="text" class="form-control" formControlName="remark" id="remark"
                                               placeholder="Enter Remarks">
                                    </div>
                                </div>
                                <div class="col-md-3"
                                     style="display: flex; align-items: center; gap: 15px; margin-top: 29px;">
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-primary"
                                                [disabled]="!transactionForm.valid || loading">Submit
                                        </button>
                                    </div>
                                    <div class="form-group">
                                        <button type="button" class="btn btn-danger" (click)="clearForm()">Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>


                    </div>
                </div>

                <div class="card d-flex" *ngIf="selectedCard === 'bulkTransferCard'">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-3">
                                <h5 class="card-title">Download Sample Sheet</h5>
                                <a class="btn btn-primary" (click)="downloadSampleSheet()">
                                    <app-feather-icons [icon]="'download'" class="p-4"></app-feather-icons>
                                    Download

                                </a>

                            </div>
                            <div class="col-sm-4">
                                <ngx-dropzone (change)="onSelect($event)" [multiple]="false" class="custom-dropzone">
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="icon-cloud-up"></i>
                                            <h6>Drop files here or click to upload.</h6>
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
                                                          (removed)="onRemove(f)">
                                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </ngx-dropzone>

                            </div>
                        </div>
                    </div>
                </div>


                <div class="card" *ngIf="selectedCard === 'apiTransferCard'">
                    <div class="card-body">
                        <h5 class="card-title">API Transfer Card</h5>
                        <p class="card-text">Under Maintainance</p>
                    </div>
                </div>

                <div class="card-body p-4">
                    <div class="col-12 account-statement-tbl">

                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="row-border hover">
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>TRN Id</th>
                                    <th>UTR</th>
                                    <th>Name</th>
                                    <th>AC No</th>
                                    <th>Amount</th>
                                    <th>Payment Mode</th>
                                    <th>Remark</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="persons?.length != 0">
                                <tr *ngFor="let item of transactionList; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ item.transactionReference }}</td>
                                    <td>{{ item.utr }}</td>
                                    <td>{{ item.accountHolderName }}</td>
                                    <td>{{ item.accountNumber }}</td>
                                    <td>{{ item.amount }}</td>
                                    <td>{{ item.paymentMode }}</td>
                                    <td>{{ item.remark }}</td>
                                    <td><span *ngIf="item.paymentStatus=='failed'"
                                              class="badge badge-light-danger">{{item.paymentStatus}}</span>
                                        <span *ngIf="item.paymentStatus=='pending'"
                                              class="badge badge-light-warning">{{item.paymentStatus}}</span>
                                        <span *ngIf="item.paymentStatus=='initiate'"
                                              class="badge badge-light-primary">{{item.paymentStatus}}</span>
                                        <span *ngIf="item.paymentStatus=='success'"
                                              class="badge badge-light-success">{{item.paymentStatus}}</span>
                                        <span *ngIf="item.paymentStatus=='rejected'"
                                              class="badge badge-light-danger">{{item.paymentStatus}}</span>
                                    </td>
                                    <td>
                                        <button class="btn btn-pill btn-primary-gradien" type="button"
                                                data-bs-original-title="" title="" (click)="getDetails(item)">View
                                        </button>
                                    </td>

                                </tr>


                                </tbody>
                                <tbody *ngIf="transactionList?.length == 0">
                                <tr>
                                    <td colspan="3" class="no-data-available">No data!</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div #receiptContent aria-modal="false" style="position: absolute; left: -9999px;">
    <app-receipt-card
            [logo]="yourLogo"
            [heading]="transactionHeading"
            [time]="transactionTime"
            [paidTo]="paidTo"
            [name]="name"
            [amount]="amount"
            [debitReference]="debitReference"
            [utrNumber]="utrNumber"
            [transactionId]="transactionId">
    </app-receipt-card>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Transaction Details</h4>
        <button *ngIf="transactionDetails.paymentStatus=='success'" class="btn btn-primary" (click)="downloadPDFHTML()" style="margin-left: auto">
            <i class="fa fa-download"></i>
        </button>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <table class="table">
            <thead>

            </thead>
            <tbody>
            <tr class="border-bottom-secondary">
                <th scope="row">Account Holder Name</th>
                <td>{{transactionDetails.accountHolderName}}</td>
            </tr>
            <tr class="border-bottom-secondary">
                <th scope="row">Account Number</th>
                <td>{{transactionDetails.accountNumber}}</td>
            </tr>
            <tr class="border-bottom-secondary">
                <th scope="row">IFSC</th>
                <td>{{transactionDetails.ifsc}}</td>
            </tr>
            <tr class="border-bottom-secondary">
                <th scope="row">Amount</th>
                <td>{{transactionDetails.amount}}</td>
            </tr>
            <tr class="border-bottom-secondary">
                <th scope="row">Remark</th>
                <td>{{transactionDetails.remark}}</td>
            </tr>
            <tr class="border-bottom-secondary">
                <th scope="row">Payment Date & Time</th>
                <td> {{ transactionDetails.paymentDateTime | date: 'dd-MM-YYY hh:mm:ss'}}</td>
            </tr>
            <tr class="border-bottom-secondary">
                <th scope="row">Payment Mode</th>
                <td>{{transactionDetails.paymentMode}}</td>
            </tr>
            <tr class="border-bottom-secondary">
                <th scope="row">Transaction Id</th>
                <td>{{transactionDetails.transactionId}}</td>
            </tr>
            <tr class="border-bottom-secondary">
                <th scope="row">Transaction Reference</th>
                <td>{{transactionDetails.transactionReference}}</td>
            </tr>
            <tr class="border-bottom-secondary">
                <th scope="row">UTR</th>
                <td>{{transactionDetails.utr}}</td>
            </tr>
            <tr class="border-bottom-secondary">
                <th scope="row">Status</th>
                <td>{{transactionDetails.paymentStatus}}</td>
            </tr>
            <tr class="border-bottom-secondary">
                <th scope="row">User Name</th>
                <td>{{transactionDetails.userName}}</td>
            </tr>
            <tr *ngIf="currentRole=='RACHAITA'" class="border-bottom-secondary">
                <th scope="row">Withdrawal Type</th>
                <td>{{transactionDetails.withdrawalType}}</td>
            </tr>

            </tbody>
        </table>
    </div>

</ng-template>
