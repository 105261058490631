<div class="card-body {{ data.colorClass }}">
  <span class="f-light">{{ data.title }}</span>
  <div class="d-flex align-items-end gap-1">
    <h4>{{ data.orders }}</h4>
    <span class="font-{{ data.colorClass }} f-12 f-w-500"
      ><i class="icon-arrow-{{ data.arrow }}"></i><span>{{ data.orderGrowth }}%</span></span
    >
  </div>
  <div class="bg-gradient">
    <svg class="stroke-icon svg-fill">
      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ data.icon }}"></use>
    </svg>
  </div>
</div>
