<app-breadcrumb [title]="'Pay-In'" [active_item]="'Pay-In'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">

                <div class="p-2">
                    <button class="btn btn-primary "
                            style="float:right" (click)="openModal()"> Add Upi</button>
                </div>
                <div class="card-body p-4">

                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="row-border hover">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Upi Id</th>
                                    <th>Account Holder Name</th>
                                    <th>Bank Name</th>
                                    <th>Balance</th>
                                    <th class="center text-center">Action</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="persons?.length != 0">
                                <tr *ngFor="let person of persons">
                                    <td>{{ person.id }}</td>
                                    <td>{{ person.firstName }}</td>
                                    <td>{{ person.firstName }}</td>
                                    <td>{{ person.firstName }}</td>
                                    <td>{{ person.lastName }}</td>
                                    <td class="d-flex   gap-1">
                                        <button class="btn btn-pill btn-primary-gradien" type="button" data-bs-original-title="" title="">Deposit</button>
                                        <button class="btn btn-pill btn-info-gradien" type="button" data-bs-original-title="" title="">Withdraw</button>
                                        <div class="media-body text-end icon-state">
                                            <label class="switch">
                                                <input type="checkbox" data-bs-original-title="" title=""><span class="switch-state"></span>
                                            </label>
                                        </div>

                                        <button class="btn btn-pill btn-danger btn-air-danger btn-danger-gradien" type="button" data-bs-original-title="" title="">
                                           Delete</button>
                                    </td>
                                </tr>


                                </tbody>
                                <tbody *ngIf="persons?.length == 0">
                                <tr>
                                    <td colspan="3" class="no-data-available">No data!</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add UPI</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form class="form theme-form">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="exampleFormControlInput1">UPI Id</label>
                            <input class="form-control" id="exampleFormControlInput1" type="text" placeholder="Enter Upi Id" data-bs-original-title="" title="">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="exampleFormControlInput1">Account Holder Name</label>
                            <input class="form-control" id="exampleFormControlInput1" type="text" placeholder="john" data-bs-original-title="" title="">
                        </div>
                    </div>
                </div> <div class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="exampleFormControlInput1">Bank Name</label>
                            <input class="form-control" id="exampleFormControlInput1" type="text" placeholder="Enter Bank Name" data-bs-original-title="" title="">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="exampleInputPassword2">Amount</label>
                            <input class="form-control" id="exampleInputPassword2" type="number" placeholder="Enter Amount" data-bs-original-title="" title="">
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Submit</button>
    </div>
</ng-template>

