import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { content } from "./shared/routes/routes";
import {ResetPasswordComponent} from "./auth/reset-password/reset-password.component";
import {AuthGuard} from "./shared/services/auth.guard";

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '',
  //   pathMatch: 'full'
  // },
  // {
  //   path: '',
  //   redirectTo: 'simple-page/first-page',
  //   pathMatch: 'full'
  // },
  {
    path: 'auth/login',
    component: LoginComponent
  },{
    path: 'auth/reset',
    component: ResetPasswordComponent
  },
  {
    path: '',
    component: ContentComponent,
    children: content,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
  })],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
