import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  role?: any[];
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    // {
    //   title: "Dashboard",
    //   icon: "home",
    //   type: "link",
    //   badgeType: "light-primary",
    //   badgeValue: "2",
    //   active: true,
    //   children: [
    //     { path: "", title: "Dashboard", type: "link" },
    //   ],
    // },
    { path: "/dashboard", icon: "home", title: "Dashboard",  active: false, type: "link", bookmark: true, role:['RACHAITA','PAYIN','AGENT','MASTER','PAYOUT', 'VIEW'] },
    { path: "/users", icon: "users", title: "Users",  active: false, type: "link", bookmark: true ,role:['RACHAITA','AGENT','MASTER']},
    { path: "/pay-in", icon: "download", title: "Pay In",  active: false, type: "link", bookmark: true, role:['RACHAITA','PAYIN'] },
    { path: "/pay-in-statement", icon: "file-text", title: "Pay In Statement",  active: false, type: "link", bookmark: true ,role:['RACHAITA','PAYIN']},
    { path: "/pay-out", icon: "upload", title: "Pay Out",  active: false, type: "link", bookmark: true, role:['RACHAITA','PAYOUT'] },
    { path: "/pay-out-statement", icon: "file-minus", title: "Pay Out Statement",  active: false, type: "link", bookmark: true, role:['RACHAITA','PAYOUT'] },
    { path: "/statement", icon: "file-text", title: "Statement",  active: false, type: "link", bookmark: true ,role:['RACHAITA','PAYIN','AGENT','MASTER','PAYOUT']},
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
