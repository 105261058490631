<div class="receipt-card">
    <div class="header">
        <img src="assets/fevicon.png" style="height: 40px">
        <div style="display: flex; flex-direction: column; float:left">
            <div class="heading">{{ heading }}</div>
            <div class="time">{{ time | date:'medium' }}</div>
        </div>
    </div>
    <div class="card">
        <div class="heading">Debit reference</div>
        <div class="details">UTR Number: {{transactionId  }}</div>
    </div>
    <div class="body">
        <div class="card">
            <div class="heading">Paid to:</div>
            <div class="d-flex flex-row justify-content-between">
            <div class="details">Name: {{ name }}</div>
            <div class="details">Amount: {{ amount }}</div>
            </div>
        </div>

        <div class="card">
            <div class="heading">Payment Mode:</div>
            <div class="details">{{ utrNumber }}</div>
        </div>
    </div>
    <span class="" style="text-align: center">
        <div class="powered-by">Powered By</div>
        <div><img src="assets/logo.png" style="height: 30px"></div>
    </span>
</div>
