import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {ActivatedRoute} from '@angular/router'
import * as feather from 'feather-icons';
import {LayoutService} from '../../../services/layout.service';
import {NavService} from '../../../services/nav.service';
import {fadeInAnimation} from '../../../data/router-animation/router-animation';
import {NotificationService} from "../../../services/notification.service";
import {SidebarComponent} from "../../sidebar/sidebar.component";

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    animations: [fadeInAnimation]
})
export class ContentComponent implements OnInit, AfterViewInit {
    @Output() closeSidebar: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild(SidebarComponent) sidebar: SidebarComponent;

    constructor(private route: ActivatedRoute, public navServices: NavService, private notification: NotificationService,
                public layout: LayoutService, private elementRef: ElementRef) {
        this.route.queryParams.subscribe((params) => {
            this.layout.config.settings.layout = params.layout ? params.layout : this.layout.config.settings.layout
        })
    }

    isMobile: boolean = false;
    mobileWidthThreshold: number = 768; // Adjust this according to your mobile breakpoint

    ngAfterViewInit() {
        this.checkIsMobile();

        setTimeout(() => {
            feather.replace();
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkIsMobile();
    }

    checkIsMobile() {
        this.isMobile = window.innerWidth < this.mobileWidthThreshold;
        // if (this.isMobile) {
        //     this.elementRef.nativeElement.ownerDocument.body.classList.add('overlay'); // Add overlay class to body
        // } else {
        //     this.elementRef.nativeElement.ownerDocument.body.classList.remove('overlay'); // Remove overlay class from body
        // }
    }

    closeSidebarOnOverlayClick() {
        console.log("closeSidebarOnOverlayClick called");
        if (this.isMobile) {
            console.log("isMobile is true");
            this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
        }
    }
    public getRouterOutletState(outlet) {
        return outlet.isActivated ? outlet.activatedRoute : '';
    }

    get layoutClass() {
        switch (this.layout.config.settings.layout) {
            case "Dubai":
                return "compact-wrapper"
            case "London":
                return "only-body"
            case "Seoul":
                return "compact-wrapper modern-type"
            case "LosAngeles":
                return this.navServices.horizontal ? "horizontal-wrapper material-type" : "compact-wrapper material-type"
            case "Paris":
                return "compact-wrapper dark-sidebar"
            case "Tokyo":
                return "compact-sidebar"
            case "Madrid":
                return "compact-wrapper color-sidebar"
            case "Moscow":
                return "compact-sidebar compact-small"
            case "NewYork":
                return "compact-wrapper box-layout"
            case "Singapore":
                return this.navServices.horizontal ? "horizontal-wrapper enterprice-type" : "compact-wrapper enterprice-type"
            case "Rome":
                return "compact-sidebar compact-small material-icon"
            case "Barcelona":
                return this.navServices.horizontal ? "horizontal-wrapper enterprice-type advance-layout" : "compact-wrapper enterprice-type advance-layout"
            case "horizontal-wrapper":
                return this.navServices.horizontal ? "horizontal-wrapper" : "compact-wrapper"
            default:
                return "compact-wrapper"
        }
    }

    ngOnInit() {
    }

}
