<div class="card visitor-card" style="height: 93%">
  <div class="card-header card-no-border">
    <div class="header-top">
      <h5 class="m-0">
        Last 6 Months Report
      </h5>
<!--      <div class="card-header-right-icon">-->
<!--        <div class="dropdown icon-dropdown">-->
<!--          <button class="btn dropdown-toggle" type="button" (click)="toggle()"><app-feather-icons [icon]="'more-horizontal'"></app-feather-icons></button>-->
<!--          <div class="dropdown-menu dropdown-menu-end" [class.show]="show"><a class="dropdown-item" href="#">Today</a><a class="dropdown-item" href="#">Tomorrow</a><a class="dropdown-item" href="#">Yesterday</a></div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
  <div class="card-body pt-0">
    <div class="visitors-container">
      <div id="visitor-chart">
        <apx-chart
          [series]="visitors.series"
          [chart]="visitors.chart"
          [plotOptions]="visitors.plotOptions"
          [dataLabels]="visitors.dataLabels"
          [stroke]="visitors.stroke"
          [grid]="visitors.grid"
          [colors]="visitors.colors"
          [xaxis]="visitors.xaxis"
          [fill]="visitors.fill"
          [legend]="visitors.legend"
          [responsive]="visitors.responsive"
        ></apx-chart>
      </div>
    </div>
  </div>
</div>
