<app-breadcrumb [title]="'Statement'"  [active_item]="'Statement'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="theme-form" style="display: flex; gap: 10px">
                    <div class="input-group w-50">
                        <div class="input-group-text">From</div>
                        <input class="datepicker-here form-control digits" placeholder="Start Date" name="startDate" [displayMonths]="displayMonths" [navigation]="navigation" [outsideDays]="outsideDays" [showWeekNumbers]="showWeekNumbers" ngbDatepicker #startDatePicker="ngbDatepicker" (click)="startDatePicker.toggle()" [(ngModel)]="startDate" (ngModelChange)="onDateChange()">
                        <div class="input-group-text">To</div>
                        <input class="datepicker-here form-control digits" placeholder="End Date" name="endDate" [displayMonths]="displayMonths" [navigation]="navigation" [outsideDays]="outsideDays" [showWeekNumbers]="showWeekNumbers" ngbDatepicker #endDatePicker="ngbDatepicker" (click)="endDatePicker.toggle()" [(ngModel)]="endDate" (ngModelChange)="onDateChange()">
                    </div>
                    <button class="btn btn-primary-gradien" (click)="rerender()">Search</button>
                </div>
                <div class="card-body p-4">
                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="row-border hover">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Credit</th>
                                    <th>Debit</th>
                                    <th>Closing</th>
                                    <th>Description</th>
                                    <th>From/To</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="statement?.length != 0">
                                <tr *ngFor="let item of statement">
                                    <td>{{ item.createdAt | date : 'short'}}</td>
                                    <td>{{ item.withdraw }}</td>
                                    <td>{{ item.deposit }}</td>
                                    <td>{{ item.bankBalance }}</td>
                                    <td>{{ item.remark }}</td>
                                    <td>{{ item.userName }}</td>
                                </tr>

                                </tbody>
                                <tbody *ngIf="statement?.length == 0">
                                <tr>
                                    <td colspan="3" class="no-data-available">No data!</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
