<div class="appointment" style="height: 100%;">
  <div class="card">
    <div class="card-header card-no-border">
      <div class="header-top">
        <h5 class="m-0">Top Bank</h5>
<!--        <div class="card-header-right-icon">-->
<!--          <div class="dropdown icon-dropdown">-->
<!--            <button class="btn dropdown-toggle" type="button" (click)="toggle()"><app-feather-icons [icon]="'more-horizontal'"></app-feather-icons></button>-->
<!--            <div class="dropdown-menu dropdown-menu-end" [class.show]="show"><a class="dropdown-item" href="#">Today</a><a class="dropdown-item" href="#">Tomorrow</a><a class="dropdown-item" href="#">Yesterday</a></div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="appointment-table customer-table table-responsive">
        <table class="table table-bordernone">
          <tbody>
            <tr>
              <td><img class="img-fluid img-40 rounded-circle me-2" src="assets/images/dashboard/user/1.jpg" alt="user" /></td>
              <td class="img-content-box"><a class="f-w-500" href="user-profile.html">RBL</a>
            </tr>
            <tr>
              <td><img class="img-fluid img-40 rounded-circle me-2" src="assets/images/dashboard/user/2.jpg" alt="user" /></td>
              <td class="img-content-box"><a class="f-w-500" href="user-profile.html">IDFC</a>
            </tr>
            <tr>
              <td><img class="img-fluid img-40 rounded-circle me-2" src="assets/images/dashboard/user/9.jpg" alt="user" /></td>
              <td class="img-content-box"><a class="f-w-500" href="user-profile.html">Floyd Miles</a>
            </tr>
            <tr>
              <td><img class="img-fluid img-40 rounded-circle me-2" src="assets/images/dashboard/user/1.jpg" alt="user" /></td>
              <td class="img-content-box"><a class="f-w-500" href="user-profile.html">RBL</a>
            </tr>
            <tr>
              <td><img class="img-fluid img-40 rounded-circle me-2" src="assets/images/dashboard/user/2.jpg" alt="user" /></td>
              <td class="img-content-box"><a class="f-w-500" href="user-profile.html">IDFC</a>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
